export default {
  "teacher.title": "您可以查看所有老师，并为学生预定老师",
  "teacher.level": "等级",
  "teacher.hour": "小时",
  "teacher.appointment": "约课",
  "teacher.showVideo": "个人展示视频",
  "teacher.teacherList": "老师列表",
  "teacher.bakcList": "返回列表",
  "teacher.showAll": "展示全部",
  "teacher.selfIntroduction": "自我介绍",
  "teacher.backgroundOverview": "个人背景",
  "teacher.educationBackground": "教育背景",
  "teacher.availableTime": "可授课时间",
  "teacher.reviews": "评价",
  "teacher.specialistSubjects": "授课科目",
  "teacher.professionalExperience": "工作经历",
  "teacher.degree": "专业",
  "teacher.matriculationDate": "入学年份",
  "teacher.levelOfDegree": "学位",
  "teacher.grade": "成绩",
  "teacher.organisation": "公司",
  "teacher.position": "岗位",
  "teacher.startDate": "开始工作",
  "teacher.endDate": "结束工作",
  "teacher.countryOfWork": "工作国家",
  "teacher.academicScope": "教学范围",
  "teacher.subject": "科目",
  "common.level": "等级",
  "teacher.taught": "已授课时",
  "teacher.book": "约课",
  "teacher.legendAvailable": "可授课时间",
  "teacher.legendClass": "上课时间",
  "teacher.legendBusy": "不可授课时间",
  "teacher.legendUnknown": "未知",
  "teacher.viewWeek": "查看固定可授课时间",
  "teacher.weekModalTitle": "按周查看可授课时间",
  "teacher.weekModalDesc":
    "此处为老师设定的每周通常可授课时间，具体时间以每日详细可授课时间为准。在蓝色区域拖动，可以直接进行约课。加*部分表示该表格和某天的实际可授课时间有出入。鼠标悬浮可以查看出入详情",
  "teacher.weekModalNotAvailable": "不可上课",
  "teacher.reservationTips": "想要预约{name}老师{time}的课程吗？",
  "teacher.reservationWeekTips": "想从哪一周的{day}{time}开始约课？",
  "teacher.startDate": "开始日期",
  "teacher.onlyTips": "仅供参考，具体以老师确认为准",
  "teacher.dragTips": "在蓝色区域拖动，可以直接进行约课",
};
