export default {
    "staff.title": '在这里可以管理您的所有员工，可以给员工发送注册邀请',
    "staff.department": '所属部门',
    "staff.unregistered": '未注册',
    "staff.registered": '已注册',
    "staff.inviteNewMember": '添加员工',
    "staff.editMember": '编辑员工',
    "staff.invite": '邀请',
    "staff.inviteTitle": '你确定要邀请 {name} 吗？',
    "staff.submitInvitation": '提交并发邀请',
    "staff.adminPrivileges": '是否为管理员',
    "staff.updateSuccess": '密码修改成功',
    "staff.sendSuccess": '邀请已发送，注册邮件将在5分钟内发送到该员工邮箱',
};