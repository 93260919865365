import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BankOutlined,
} from "@ant-design/icons";
import { Select } from "antd";
import { getItem } from "@/utils/common";

const { Option } = Select;

class CompanyOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: "", // 当前用户权限
      agent: {}, // 当前用户信息
      companies: [],
    };
  }
  componentDidMount() {
    let auth = getItem("auth");
    let agent = getItem("agent") || {};
    this.setState({ auth, agent });
    if (auth === "admin") {
      this.getAgentCompanies();
    }
  }

  getAgentCompanies() {
    this.props.getAgentCompanies().then((res) => {
      if (res && res.status === 200) {
        res = res.data;
        this.setState({ companies: res.data.companies });
      }
    });
  }

  signOut = () => {
    localStorage.removeItem("agent");
    localStorage.removeItem("token");
    localStorage.removeItem("auth");
    this.props.closeWebSocket();
    window.router.history.replace("/login");
  };

  changeCompany = ({id, name}) => {
    const data = { company: name, company_id: id };
    this.props.changeCompany(data).then((res) => {
      if (res && res.status === 200) {
        this.signOut();
      }
    });
  };

  render() {
    const { isSmallScreen } = this.props
    const { auth, agent, companies } = this.state;
    const { company_id } = agent;
    
    return auth === "admin" ? (
      <div className="right userInfo" style={{ width: 150, marginRight: !isSmallScreen && 15}}>
        <Select
          showSearch
          defaultValue={company_id}
          style={{ width: '100%' }}
          optionFilterProp="children"
          suffixIcon={
            <BankOutlined style={{ fontSize: 18, marginTop: "-3px" }} />
          }
          onChange={(selected) => this.changeCompany(companies.find(c => c.id===selected))}
          filterOption={(input, option) =>
            option.props.children
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          {companies.map((item, index) => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>{" "}
          &emsp;
      </div>
    ) : null;
  }
}

const mapState = (models) => ({
  company: models.userInfo.company,
});

const mapDispatch = (models) => ({
  closeWebSocket: (data) => models.chatRoom.closeWebSocket(data),
  changeCompany: (data) => models.auth.changeCompany(data),
  getAgentCompanies: (data) => models.auth.getAgentCompanies(data),
});

export default connect(mapState, mapDispatch)(CompanyOptions);
