import menu from './zh-CN/menu';
import course from './zh-CN/course';
import common from './zh-CN/common';
import teacher from './zh-CN/teacher';
import student from './zh-CN/student';
import staff from './zh-CN/staff';
import order from './zh-CN/order';
import statistic from './zh-CN/statistic';
import login from './zh-CN/login';

export default {
    ...menu,
    ...course,
    ...common,
    ...teacher,
    ...student,
    ...staff,
    ...order,
    ...statistic,
    ...login,
};
