const chatView = {
    state: {
        previewVisible: false,
        previewImage: '',
        searchChatText: '',
    },
    reducers: {
        updateCommonState: (state, payload) => {
            return { ...state, ...payload }
        }
    },
    effects: {
        handleSomeState (payload) {
            this.updateCommonState(payload)
        },
        changeState (payload) {
            this.handleSomeState(payload);
        },
        searchChat (text) {
            this.handleSomeState({searchChatText: text});
        }
    }
};

export default chatView;
