import React from "react";
import { connect } from "react-redux";
import {
  CheckCircleFilled,
  FolderTwoTone,
  UpOutlined,
} from "@ant-design/icons";
import { Comment, List, Popover, Modal, Card, Button, Tooltip } from "antd";
import moment from "moment";
import { getItem, formatTime } from "@/utils/common";
import cn from "classnames";

const { Meta } = Card;
const detailsIcon = require("@/assets/images/details-icon.png");
const MSG_CODE = require("@/store/msg_code.json"); // 消息码
let jwtDecode = require("jwt-decode");
const ChatView = (props) => {
  let once = false;
  const handleCancelImage = () => {
    props.changeState({ previewVisible: false });
  };
  const handleShowImage = (src) => {
    props.changeState({ previewVisible: true, previewImage: src });
  };
  const translateApi = (messageId, text, type) => {
    let value = type === "zh" ? "译文：" : "translation: ";
    props.getTranslate({ type, text }).then((res) => {
      if (res && res.status === 200) {
        res = res.data;
        value += res.data[0].Text;
        setTranslateApiValue(messageId, value);
      }
    });
  };

  const setTranslateApiValue = (messageId, value = "") => {
    props.changeTranslateWord({ messageId, value });
  };
  const getActiveRoomData = () => {
    if (props.room_list.length <= 0) {
      return "";
    }
    return (
      props.room_list.find((item) => {
        return item.id === props.active_room;
      }) || ""
    );
  };
  const getMemberByGroup = (groupItem) => {
    if (!groupItem) return { members: {}, members_arr: [] };
    let members = props.chat_members;
    let members_arr = [].concat(
      groupItem.agent_ids,
      groupItem.student_ids,
      groupItem.teacher_ids
    );
    members_arr = members_arr
      .filter((item) => members[item])
      .map((item) => members[item]);
    return { members, members_arr };
  };
  let activeRoomData = getActiveRoomData();
  let { members, members_arr } = getMemberByGroup(activeRoomData);
  return (
    <div className="chat-view">
      <div className="group-title">
        {activeRoomData && (
          <span className="group-name ellipsis">
            {activeRoomData.title}
            {activeRoomData.alias_name && `-${activeRoomData.alias_name}`}
            <span className="member-num">({members_arr.length})</span>
          </span>
        )}
        <div className={"group-detail-icon right"}>
          <Popover
            placement="bottomRight"
            title={"Group members"}
            content={
              <div style={{ width: "400px" }}>
                <div className={"chat-room-group-detail"}>
                  {members_arr.map((item, index) => (
                    <Card
                      key={index}
                      hoverable
                      className={"left"}
                      style={{ width: 120, margin: "6px", border: 0 }}
                      cover={
                        item.extra && item.extra.profile_pic ? (
                          <img
                            alt=""
                            src={`${item.extra.profile_pic}?x-oss-process=style/thumbnail`}
                          />
                        ) : (
                          <div
                            className={`chat-room-user-avatar bigger ${item._role}`}
                          >
                            {item._name.substring(0, 1).toUpperCase()}
                          </div>
                        )
                      }
                    >
                      <Meta
                        title={item._name}
                        style={{
                          padding: "0 10px",
                        }}
                        description={item._role}
                      />
                    </Card>
                  ))}
                </div>
              </div>
            }
            trigger="click"
          >
            <img src={detailsIcon} alt="" />
          </Popover>
        </div>
      </div>
      <List
        id="message-window"
        className="message-window"
        itemLayout="horizontal"
        onScroll={(event) => {
          let Target = event.target;
          // 获取更多历史消息
          if (Target.scrollHeight > Target.clientHeight) {
            if (Target.scrollTop <= 0) {
              props.getMoreMessage();
            }
          }
          // 读取当前活动窗口未读消息
          if (props.is_unread_message) {
            if (
              Target.scrollHeight - Target.scrollTop <=
              Target.clientHeight + 20
            ) {
              if (!once) {
                props.scrollToEnd();
                once = true;
                let timer = setTimeout(() => {
                  once = false;
                  clearTimeout(timer);
                }, 5000);
              }
            }
          }
        }}
        dataSource={props.last_msg[props.active_room]}
        renderItem={(item, index) => {
          const token = getItem("token");
          const decoded = token ? jwtDecode(token) : {};
          const isMe = item.author_id === decoded.id;
          let showTime = true;
          if (index !== 0) {
            let last_timestamp =
              props.last_msg[props.active_room][index - 1].created_at;
            if (
              moment(item.created_at).diff(moment(last_timestamp), "minutes") <
              5
            ) {
              showTime = false;
            }
          }
          let name = members[item.author_id]
            ? members[item.author_id]._name
            : "Unknown user";
          return (
            <div
              key={index}
              className={cn("message-item", { "my-message": isMe })}
            >
              {showTime && item.code !== MSG_CODE["sysMsg"] && (
                <div className={"sys-msg"}>
                  <span>{formatTime(item.created_at || 0)}</span>
                </div>
              )}
              {item.code === MSG_CODE["sysMsg"] ||
              item.code === MSG_CODE["mention"] ? (
                <div className={"sys-msg"}>
                  <span>
                    {item.value}
                    <br />
                    {item.code === MSG_CODE["sysMsg"] && (
                      <p style={{ textAlign: "center" }}>
                        {formatTime(item.created_at || 0)}
                      </p>
                    )}
                  </span>
                </div>
              ) : (
                <Comment
                  author={!isMe ? name : ""}
                  avatar={
                    members[item.author_id]?.extra?.profile_pic ? (
                      `${
                        members[item.author_id].extra.profile_pic
                      }?x-oss-process=style/thumbnail`
                    ) : (
                      <div
                        className={`chat-room-user-avatar ${
                          members[item.author_id]?._role
                        }`}
                      >
                        {name.substring(0, 1)}
                      </div>
                    )
                  }
                  content={
                    <div className={"message-view-item"}>
                      {item.code === MSG_CODE["img"] && (
                        <div
                          className="image"
                          onClick={() => handleShowImage(item.value)}
                        >
                          <img
                            src={item.value}
                            onLoad={(e) => {
                              e.target.setAttribute("style", `height: auto`);
                            }}
                            alt={""}
                          />
                        </div>
                      )}
                      {item.code === MSG_CODE["file"] && (
                        <a
                          className={"file-link"}
                          download
                          href={item.value}
                          title={item.value}
                        >
                          <FolderTwoTone />
                          <b>
                            {item.value.split("/").pop().split(".").length > 1
                              ? "." +
                                item.value
                                  .split("/")
                                  .pop()
                                  .split(".")
                                  .pop()
                                  .split("?")[0]
                              : "⚠️ Unknown file type！"}
                          </b>
                          <span>
                            {decodeURIComponent(
                              item.value.split("/").pop().split("?")[0]
                            )}
                          </span>
                        </a>
                      )}
                      {item.code === MSG_CODE["word"] ? (
                        isMe ? (
                          <div
                            className={"word"}
                            dangerouslySetInnerHTML={{ __html: item.value }}
                          />
                        ) : (
                          <div className={"tooltip"}>
                            <Tooltip
                              placement="topLeft"
                              title={
                                <Button.Group
                                  className={"chat-message-contextMenu"}
                                >
                                  <Button
                                    onClick={() =>
                                      translateApi(
                                        item.id,
                                        document.getElementById(item.id)
                                          .innerText,
                                        "zh"
                                      )
                                    }
                                    ghost
                                  >
                                    翻译
                                  </Button>
                                  <Button
                                    onClick={() =>
                                      translateApi(
                                        item.id,
                                        document.getElementById(item.id)
                                          .innerText,
                                        "en"
                                      )
                                    }
                                    ghost
                                  >
                                    Translate
                                  </Button>
                                </Button.Group>
                              }
                            >
                              <div
                                className={"word"}
                                id={item.id}
                                dangerouslySetInnerHTML={{ __html: item.value }}
                              />
                            </Tooltip>
                            {item.translate_word && (
                              <div className={"word message-translate"}>
                                <p>{item.translate_word}</p>
                                <span
                                  onClick={() => setTranslateApiValue(item.id)}
                                >
                                  <u>
                                    <CheckCircleFilled />
                                    google
                                  </u>
                                  <UpOutlined />
                                  retract
                                </span>
                              </div>
                            )}
                          </div>
                        )
                      ) : null}
                    </div>
                  }
                />
              )}
            </div>
          );
        }}
      />
      <div className={cn({ "unread-message": props.is_unread_message })}>
        <span onClick={props.scrollToEnd}>
          {props.unread_msg_count[props.active_room]} unread message
          {props.unread_msg_count[props.active_room] !== 1 ? "s" : ""}
        </span>
      </div>
      <Modal
        className={"preview-image-modal"}
        visible={props.previewVisible}
        footer={null}
        onCancel={handleCancelImage}
      >
        <img alt="" src={props.previewImage} />
      </Modal>
    </div>
  );
};

const mapState = (models) => ({
  active_room: models.chatRoom.active_room,
  last_msg: models.chatRoom.last_msg,
  room_list: models.chatRoom.room_list,
  unread_msg_count: models.chatRoom.unread_msg_count,
  is_unread_message: models.chatRoom.is_unread_message,
  previewVisible: models.chatView.previewVisible,
  previewImage: models.chatView.previewImage,
  chat_members: models.chatRoom.chat_members,
});

const mapDispatch = (models) => ({
  scrollToEnd: () => models.chatRoom.scrollToEnd({ isForce: true }),
  getMoreMessage: () => models.chatRoom.getMoreMessage(),
  changeState: (state) => models.chatView.changeState(state),
  changeTranslateWord: (payload) =>
    models.chatRoom.changeTranslateWord(payload),
  getTranslate: (payload) => models.common.getTranslate(payload),
});

export default connect(mapState, mapDispatch)(ChatView);
