export default {
  "order.title":
    "Your chosen teacher will receive a notification of your booking request. Once the teacher has confirmed, the class will go ahead as scheduled.",
  "order.bookingDesc": "Currently booking a course with {name}",
  "order.student": "Student",
  "order.classTime": "Class Time",
  "order.addClassTime": "Add New Class Time",
  "order.classTimeTip":
    "This time is not recommended to book, the tutor might reject the request",
  "order.classTimeTipError": "This time is not available",
  "order.classTimeTipHasClass":
    "The tutor has other classes booked in this time period",
  "order.courseRequirements":
    "Course Requirements (Here you can mention any aspects of the student’s precise requirements/areas you hope for the teacher to focus on)",
  "order.courseRequirementsTips":
    "The course requirements will be sent to the student once your the booking is submitted",
  "order.courseRequirementsTipsTwo":
    "Please speak to the teacher in English in order to ensure smooth communication",
  "order.file":
    "File (eg. Student’s desired textbook, screenshots of homework etc)",
  "order.file_Whetstone": "Upload your personal statement",
  "order.uploadFile": "Upload File",
  "order.supportedFiles": "Supported Files: .rar .zip .doc .docx. pdf. jpg…",
  "order.modalTitle":
    "Class time (Here you can set a number of recurring weeks to schedule class for a fixed time every week. Please select the student’s timezone in order to avoid any potential scheduling errors due to daylight savings time)",
  "order.date": "Date",
  "order.week": "Weekday",
  "order.startTime": "Start Time",
  "order.classLength": "Class Length",
  "order.placeholder.duration": "1 hour",
  "order.placeholder.repeat": "5 weeks/classes",
  "order.weeksNumber": "Recurring Weeks",
  "order.selectDate": "Please select a date",
  "order.creatingDirectOrder": "Creating a Direct Class Booking Request",
  "order.confirmReschedule": "Please confirm the newly rescheduled class time",
  "order.currentlyRescheduling": "Currently Rescheduling {content}",
  "order.batchReschedule": "Batch Reschedule：",
  "order.length": "Length",
  "order.originalTime": "Original Time",
  "order.endTime": "End Time",
  "order.rescheduleSuccess": "Reschedule Request Successfully Submitted",
  "order.classDetail": "Class Detail",
  "order.time": "Time",
  "order.teacher": "Teacher",
  "order.teacherLevel": "Teacher Level",
  "order.teacherGender": "Teacher Gender",
  "order.highestEducationDegree": "Highest Education Degree",
  "order.teacherNationality": "Teacher Nationality",
  "order.classBookingTimestamp": "Class Booking Timestamp",
  "order.academicRequirement": "Academic Requirement",
  "order.filesEg": "Files（E.g. Textbooks, questions）",
  "order.classRecording": "Class Recording",
  "order.classReport": "Class Report",
  "order.viewVideo": "View Video",
  "order.viewClassReport": "View Class Report",
  "order.tipsModalTitle": "Alert",
  "order.tipsModalContent":
    "Because you are adding classes to an existing course, the requirements won’t be sent to any other teachers even if your chosen teacher does not accept",
  "order.tipsModalBtn": "Roger that!",
  "order.tipsOther": "Please select at least one complete timeslot",
  "order.tipsConfirmSuccess": "Successfully confirmed",
  "order.cancelSucess": "Successfully cancelled",
  "order.tips": "Line number [{number}] is not filled out fully",
};
