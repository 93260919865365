import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "@ant-design/compatible";
import { Input, Select, Modal, Button } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import IntlTelInput from "react-intl-tel-input";
import {  getToken } from "@/utils/common";
import "@ant-design/compatible/assets/index.css";
import "react-intl-tel-input/dist/main.css";
import "./intl-tel-input.less";

const FormItem = Form.Item;
const Option = Select.Option;

class AddStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isPhoneChecked: "validating",
      isParentPhoneChecked: "validating",
      mobile_prefix: "86",
      mobile_number: "",
      parent_phone: "",
    };
  }
  async componentDidMount() {
    if (this.props.initStudentInfo && this.props.initStudentInfo !== {})
      this.setPhoneNum(this.props.initStudentInfo);
  }
  setPhoneNum(p) {
    this.setState({
      mobile_prefix: p.mobile_prefix || "",
      mobile_number: p.mobile_number || "",
      parent_phone: p.parent_phone || "",
    });
  }
  submitForm = () => {
    const { form, intl } = this.props;
    form.validateFields((err, values) => {
      if (this.state.isPhoneChecked !== "success") {
        this.setState({ isPhoneChecked: "error" });
        return;
      }
      if (
        this.state.parent_phone &&
        this.state.isParentPhoneChecked !== "success"
      ) {
        this.setState({ isParentPhoneChecked: "error" });
        return;
      }
      if (err) return;
      let params = {
        email: values.email,
        mobile_prefix: this.state.mobile_prefix,
        mobile_number: this.state.mobile_number,
        sex: parseInt(values.sex, 10),
        birthday: values.birthday,
        first_name: values.first_name,
        zh_name: intl.locale === "zh" ? values.zh_name : values.first_name,
        timezone: values.timezone,
        parent_phone: this.state.parent_phone,
      };
      this.setState({ loading: true });
      if (!this.props.initStudentInfo.id) {
        this.props.addStudent(params).then((res) => {
          if (res && res.status === 201) {
            this.closeModal();
          }
          this.setState({ loading: false });
        });
      } else {
        this.props
          .editStudent({
            id: this.props.initStudentInfo.id,
            option: params,
          })
          .then((res) => {
            if (res && res.status === 202) {
              this.closeModal();
            }
            this.setState({ loading: false });
          });
      }
    });
  };

  closeModal = () => {
    const form = this.props.form;
    form.resetFields();
    this.props.changeStudentPopup(false);
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const { initStudentInfo, intl } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const title = !initStudentInfo.id ? (
      <FormattedMessage id="student.addStudent" />
    ) : (
      <FormattedMessage id="student.editStudent" />
    );
    const okText = <FormattedMessage id="common.submit" />;

    return (
      <Modal
        style={{ top: 20 }}
        title={title}
        maskClosable={false}
        visible
        onCancel={this.closeModal}
        footer={[
          <Button key="back" onClick={this.closeModal}>
            <FormattedMessage id="common.cancel" />
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={this.state.loading}
            onClick={this.submitForm}
          >
            {okText}
          </Button>,
        ]}
      >
        <Form>
          {intl.locale === "zh" ? (
            <FormItem
              {...formItemLayout}
              label={<FormattedMessage id="common.chineseName" />}
            >
              {getFieldDecorator("zh_name", {
                initialValue: initStudentInfo.zh_name,
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage id="common.tips.pleaseEnterChineseName" />
                    ),
                  },
                ],
              })(
                <Input
                  placeholder={intl.formatMessage({ id: "common.pleaseEnter" })}
                />
              )}
            </FormItem>
          ) : null}
          <FormItem
            {...formItemLayout}
            label={<FormattedMessage id="common.englishName" />}
          >
            {getFieldDecorator("first_name", {
              initialValue: initStudentInfo.first_name,
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage id="common.tips.pleaseEnterEnglishName" />
                  ),
                },
              ],
            })(
              <Input
                placeholder={intl.formatMessage({ id: "common.pleaseEnter" })}
              />
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={<FormattedMessage id="common.sex" />}
          >
            {getFieldDecorator("sex", {
              initialValue:
                (initStudentInfo.sex && initStudentInfo.sex.toString()) || "1",
              rules: [
                {
                  required: true,
                  message: "请选择性别",
                },
              ],
            })(
              <Select>
                <Option value="1">
                  <FormattedMessage id="common.male" />
                </Option>
                <Option value="2">
                  <FormattedMessage id="common.female" />
                </Option>
              </Select>
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={<FormattedMessage id="common.timezone" />}
          >
            {getFieldDecorator("timezone", {
              initialValue:
                (initStudentInfo.timezone &&
                  initStudentInfo.timezone.toString()) ||
                "Asia/Shanghai",
              rules: [
                {
                  required: true,
                  message: "请选择时区",
                },
              ],
            })(
              <Select showSearch>
                {this.props.timezones.map((item, index) => {
                  return (
                    <Option
                      key={"timezones-" + index}
                      value={item.name}
                    >{`${item.name} (${item.abbrev})`}</Option>
                  );
                })}
              </Select>
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            validateStatus={this.state.isPhoneChecked}
            hasFeedback={this.state.isPhoneChecked === "success"}
            help={
              this.state.isPhoneChecked === "error" ? (
                <FormattedMessage id="common.tips.pleaseEnterAValidPhoneNumber" />
              ) : (
                ""
              )
            }
            label={
              <span>
                <font color="red">*</font>{" "}
                <FormattedMessage id="common.phone" />
              </span>
            }
          >
            <div className={"addStudent-intl-tel-input-container"}>
              <IntlTelInput
                defaultCountry="cn"
                autoPlaceholder={false}
                defaultValue={
                  this.state?.mobile_number
                    ? "+" + this.state?.mobile_prefix + this.state.mobile_number
                    : ""
                }
                inputClassName={"intl-tel-input-text"}
                preferredCountries={["cn", "us", "gb", "sg"]}
                onPhoneNumberChange={(checked, number, obj) => {
                  this.setState({
                    isPhoneChecked: checked ? "success" : "validating",
                    mobile_prefix: obj.dialCode,
                    mobile_number: number,
                  });
                }}
                onSelectFlag={(number, obj, allNumber, checked) => {
                  this.setState({
                    isPhoneChecked: checked ? "success" : "error",
                    mobile_prefix: obj.dialCode,
                    mobile_number: number,
                  });
                }}
              />
            </div>
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={<FormattedMessage id="common.birth" />}
          >
            {getFieldDecorator("birthday", {
              rules: [
                {
                  pattern: /^\d{4}(\-)(((0)[0-9])|((1)[0-2]))(\-)([0-2][0-9]|(3)[0-1])$/i, // eslint-disable-line
                  message: (
                    <FormattedMessage id="common.tips.pleaseEnterTheCorrectBirthdayFormat" />
                  ),
                },
              ],
              initialValue:
                (initStudentInfo.birthday &&
                  initStudentInfo.birthday.slice(0, 10)) ||
                "",
            })(<Input placeholder="YYYY-MM-DD" />)}
          </FormItem>
          
          <FormItem
            {...formItemLayout}
            label={<FormattedMessage id="common.email" />}
          >
            {getFieldDecorator("email", {
              initialValue: initStudentInfo.email || "",
              rules: [
                {
                  type: "email",
                  required: getToken().company_id === '00000000-0000-0000-0000-000000000001',
                  message: (
                    <FormattedMessage id="common.tips.EmailFormatIsIncorrect" />
                  ),
                },
              ],
            })(
              <Input
                placeholder={intl.formatMessage({ id: "common.pleaseEnter" })}
              />
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            validateStatus={this.state.isParentPhoneChecked}
            hasFeedback={this.state.isParentPhoneChecked === "success"}
            help={
              this.state.isParentPhoneChecked === "error"
                ? intl.formatMessage({ id: "login.entryValidPhone" })
                : ""
            }
            label={<FormattedMessage id="student.parentPhone" />}
          >
            <div className={"addStudent-intl-tel-input-container"}>
              <IntlTelInput
                defaultCountry="cn"
                autoPlaceholder={false}
                defaultValue={this.state.parent_phone}
                preferredCountries={["cn", "us", "gb"]}
                onPhoneNumberChange={(checked, number, obj, allNumber) => {
                  this.setState({
                    isParentPhoneChecked: checked ? "success" : "validating",
                    parent_phone: allNumber,
                  });
                }}
                onSelectFlag={(number, obj, allNumber, checked) => {
                  this.setState({
                    isParentPhoneChecked: checked ? "success" : "error",
                    parent_phone: allNumber,
                  });
                }}
              />
            </div>
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

const AddStudentForm = Form.create()(AddStudent);

const mapState = (models) => ({
  initStudentInfo: models.common.initStudentInfo,
  timezones: models.student.timezones,
});

const mapDispatch = (models) => ({
  changeStudentPopup: (data) => models.common.changeStudentPopup(data),
  editStudent: (data) => models.student.editStudent(data),
  addStudent: (data) => models.student.addStudent(data),
});

export default injectIntl(connect(mapState, mapDispatch)(AddStudentForm));
