export default {
    "student.title": '在这里可以管理您的所有学生，可以了解他们的课程或帮他们约课',
    "student.parentPhone": '家长电话',
    "student.addStudent": '添加学生',
    "student.enterName": '输入姓名',
    "student.timetable": '课程表',
    "student.restore": '恢复',
    "student.downTimeTableTitle": '下载指定时间的课表',
    "student.restoreTitle": '你确定要恢复 {name} 吗',
    "student.restoreDesc": '恢复后您可以在全部中看到他的信息',
    "student.editStudent": '编辑学生',
    "student.deleteTitle": '你确定要删除 {name} 吗?',
    "student.deleteDesc": '删除后您仍可以在回收站中看到他的信息',
    "student.deleteDescOther": '删除后您将无法再看到他的信息',
};