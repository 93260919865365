export default {
  "teacher.title": "Here you can view teachers’ profiles and schedule classes",
  "teacher.level": "Level",
  "teacher.hour": "Hour",
  "teacher.appointment": "Book Class",
  "teacher.showVideo": "Video Self-Introduction",
  "teacher.teacherList": "Teacher List",
  "teacher.bakcList": "Return to Teacher List",
  "teacher.showAll": "Display All",
  "teacher.selfIntroduction": "Self-Introduction",
  "teacher.backgroundOverview": "Background Overview",
  "teacher.educationBackground": "Education Background",
  "teacher.availableTime": "Available Time",
  "teacher.reviews": "Reviews",
  "teacher.professionalExperience": "Professional Experience",
  "teacher.degree": "Degree",
  "teacher.matriculationDate": "Matriculation Date",
  "teacher.levelOfDegree": "Level of Degree",
  "teacher.grade": "Grade/GPA",
  "teacher.organisation": "Organisation",
  "teacher.position": "Position/Title",
  "teacher.startDate": "Start Date",
  "teacher.endDate": "End Date",
  "teacher.countryOfWork": "Country of Work",
  "teacher.academicScope": "Academic Scope",
  "teacher.subject": "Subject(s)",
  "common.level": "Level",
  "teacher.taught": "Hours Already Taught",
  "teacher.specialistSubjects": "Specialist Subjects",
  "teacher.book": "Book",
  "teacher.legendAvailable": "Available time",
  "teacher.legendClass": "Booked time",
  "teacher.legendBusy": "Busy hours",
  "teacher.legendUnknown": "Unknown",
  "teacher.viewWeek": "Check the regular available teaching time",
  "teacher.weekModalTitle": "Check the weekly available time",
  "teacher.weekModalDesc":
    "Here is a showing of the tutors weekly available teaching time set by the tutors. If the tutor has some time changes on a particular day, it's indicated by a '*' sign on the calendar. Hover your mouse over the time slot to check the details. Click and drag on the blue slots on the calendar to directly book classes",
  "teacher.weekModalNotAvailable": "Not available",
  "teacher.reservationTips": "Do you want to book tutor {name} for {time}?",
  "teacher.reservationWeekTips":
    "Please select which {day} {time} you want to start the class?",
  "teacher.startDate": "Start Date",
  "teacher.onlyTips":
    "For reference only, the teacher's confirmation shall prevail",
  "teacher.dragTips":
    "click and drag on the blue slots on the calendar to directly book classes",
};
