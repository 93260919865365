import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link }from 'react-router-dom'
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Menu, Layout } from 'antd';
import { FormattedMessage } from 'react-intl';
import { getToken } from '@/utils/common';

const { Sider } = Layout;

const DEFAULT_MENU_KEY = "/course";
const MENU_LIST = [
    {
        key: "/course",
        name: "course",
        url: "/",
        icon: "calculator",
        label: "课程",
    },{
    //     key: "/order",
    //     url: "/order",
    //     icon: "container",
    //     label: "订单",
    // },{
        key: "/teacher",
        name: getToken().company !== 'Whetstone' ? 'teacher' : 'teacherWhetstone',
        url: "/teacher",
        icon: "solution",
        label: "老师",
    },{
        key: "/student",
        name: "student",
        url: "/student",
        icon: "team",
        label: "学生",
    },{
        key: "/staff",
        name: "staff",
        url: "/staff",
        icon: "user",
        label: "员工",
    },{
        key: "/statistic",
        name: "statistic",
        url: "/statistic",
        icon: "ordered-list",
        label: "统计",
    },
];
class MenuList extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    
    toggleCollapsed = () => {
        this.props.toggleCollapsed(!this.props.collapsed)
    };
    
    getSelectedKeys = (curPath) => {
        curPath = curPath === "/" ? DEFAULT_MENU_KEY : curPath;
        let keys = [];
        for (let item of MENU_LIST) {
            if (curPath.indexOf(item.key) === 0) {
                keys.push(item.key)
            }
        }
        return keys
    };

    render() {
        const { collapsed, collapsible, curPath, logo, logo_side, logo_bg } = this.props;

        return (
            <Sider
                className='menu-container'
                collapsible={collapsible}
                width={200}
                collapsed={collapsed}
                onCollapse={this.toggleCollapsed}>
                <div className="logo" style={logo_bg?{background: logo_bg}:{}}>
                    { !collapsed && <Link to="/"><img alt="toptutor" src={logo}/></Link> }
                    { collapsed && <Link to="/"><img alt="toptutor" src={logo_side}/></Link> }
                </div>
                <Menu theme='dark' 
                    selectedKeys={this.getSelectedKeys(curPath)}
                    mode="inline">
                    {MENU_LIST.map(item=>
                        <Menu.Item key={item.key}>
                            <Link to={item.url}>
                                <LegacyIcon type={item.icon} style={{ fontSize: '20px', verticalAlign: '-4px' }} />
                                {/* <span>{item.label}</span> */}
                                <span><FormattedMessage id={`menu.${item.name}`}/></span>
                            </Link>
                        </Menu.Item>
                    )}
                </Menu>
            </Sider>
        )
    }
}

const mapState = models => ({
    collapsed: models.common.collapsed,
    curPath: models.common.curPath,
    logo: models.common.logo,
    logo_side: models.common.logo_side,
    logo_bg: models.common.logo_bg,
});

const mapDispatch = models => ({
    toggleCollapsed: (status) => models.common.toggleCollapsed(status)
});

export default connect(mapState, mapDispatch)(MenuList)
