import menu from './en_US/menu';
import course from './en_US/course';
import common from './en_US/common';
import teacher from './en_US/teacher';
import student from './en_US/student';
import staff from './en_US/staff';
import order from './en_US/order';
import statistic from './en_US/statistic';
import login from './en_US/login';

export default {
    ...menu,
    ...course,
    ...common,
    ...teacher,
    ...student,
    ...staff,
    ...order,
    ...statistic,
    ...login,
};
