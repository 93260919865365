import * as server from '@/utils/api';
import { getItem } from '@/utils/common';

const common = {
    
    state: {
        collapsed: false,
        curPath: '',
        totalPage: 0,
        studentPopupStatus: false,
        initStudentInfo: {},
        operatorPopupStatus: false,
        initOperatorInfo: {},
        rescheduleStatus: false,
        classTimePopupStatus: false,
        initclassTimeInfo: {},
        qualification: {},
        viewOrderStatus: false,
        rescheduleTimeStatus: false,
        confirmTimePopupStatus: false,
        studentShowCourseDL: false,
        logo: "",
        logo_login: "",
        logo_bg: "",
        companyPrice: ''
    },
    
    reducers: {
        updateCommonState: (state, payload) => {
            return { ...state, ...payload }
        }
    },
    
    effects: {
        toggleCollapsed (payload) {
            this.updateCommonState({collapsed: payload})
        },
        updataRouter (payload) {
            this.updateCommonState({curPath: payload})
        },
        updataTotalPage (payload) {
            this.updateCommonState({totalPage: payload})
        },
        changeStudentPopup (status, state, payload) {
            this.updateCommonState({studentPopupStatus: status, initStudentInfo: payload || {}})
        },
        changeShowStudentCourseList (status, state, payload) {
            this.updateCommonState({studentShowCourseDL: status, initStudentInfo: payload })
        },
        changeOperatorPopup (status, state, payload) {
            this.updateCommonState({operatorPopupStatus: status})
            if (payload) {
                this.updateCommonState({initOperatorInfo: payload})
            } else {
                this.updateCommonState({initOperatorInfo: {}})
            }
        },
        changeClassTimePopup (status, state, payload) {
            this.updateCommonState({classTimePopupStatus: status})
            if (payload) {
                this.updateCommonState({initclassTimeInfo: payload})
            } else {
                this.updateCommonState({initclassTimeInfo: {}})
            }
        },
        changeRescheduleVisible (status, state, payload) {
            this.updateCommonState({rescheduleStatus: status})
        },
        async getQualification () {
            let res = await server.getQualification()
            if (res && res.status === 200) {
                let options = res.data.options || {}
                this.updateCommonState({qualification: options})
            }
        },
        async getTranslate (payload) {
            return await server.getTranslate(payload)
        },
        changeViewOrderPopup (status) {
            this.updateCommonState({viewOrderStatus: status})
        },
        changeRescheduleTimeStatus (status) {
            this.updateCommonState({rescheduleTimeStatus: status})
        },
        changeConfirmTimePopupStatus (status) {
            this.updateCommonState({confirmTimePopupStatus: status})
        },
        async getPriceByCompany () {
            let companyPrice = getItem("companyPrice");
            if (companyPrice) {
                this.updateCommonState({companyPrice})
            }
            let res = await server.getPriceByCompany();
            if (res && res.status === 200) {
                let data = res.data.data;
                localStorage.setItem("companyPrice", JSON.stringify(data.price));
                this.updateCommonState({companyPrice: data.price})
            }
        },
        async getSiteInfo (host) {
            let siteInfo = getItem("siteInfo");
            if (siteInfo) {
                document.title = siteInfo.title;
                let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
                link.type = 'image/x-icon';
                link.rel = 'shortcut icon';
                link.href = siteInfo.site_icon;
                document.getElementsByTagName('head')[0].appendChild(link);
                this.updateCommonState(siteInfo)
            }
            let res = await server.getSiteInfo(host);
            if (res && res.status === 200) {
                let data = res.data.data;
                // host: ""
                // icon: ""
                // id: ""
                // logo: ""
                // logo_login: ""
                // title: ""
                document.title = data.title || '\u200E';
                let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
                link.type = 'image/x-icon';
                link.rel = 'shortcut icon';
                link.href = data.icon;
                document.getElementsByTagName('head')[0].appendChild(link);
                let __state = {
                    title: data.title || '\u200E',
                    site_icon: data.icon,
                    logo: data.logo,
                    logo_login: data.logo_login,
                    logo_bg: data.logo_bg ,
                    logo_side: data.logo_side
                };
                localStorage.setItem("siteInfo", JSON.stringify(__state));
                this.updateCommonState(__state)
            }
        },
        otherMessage (payload) {
            let message = payload;
            switch (message.code) {
                default:
                    break;
            }
        }
    }
};

export default common
