import React, { Component } from "react";
import { Drawer } from 'antd';
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import cn from "classnames";
import moment from "moment";
import jwtDecode from "jwt-decode";
import "./App.less";
import { IntlProvider } from "react-intl";
import Routes from "@/routes";
import FancyRoute from "@/routes/FancyRoute";
import PageContainer from "@/components/Common/ScrollToTop";
import Menu from "@/components/Menu";
import Header from "@/components/Header";
import AddStudent from "@/components/Student/AddStudent";
import { message } from "antd";
import { baseURL } from "@/utils/request";
import axios from "axios";

import ChatRoom from "@/components/ChatRoom/index";
import AgentsOptions from '@/components/AgentsOptions'
import CompanyOptions from '@/components/CompanyOptions'

import { getItem } from "@/utils/common";
import messages from "@/locales/index";

const getToken = () => {
  let token = getItem("token");
  return token ? jwtDecode(token) : {};
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      isLogin: false,
      lang: 'zh',
      visibleDrawer: false,
      isSmallScreen: false
    };
    this.headerRef = React.createRef();
  }
  router;
  componentDidMount() {
    const localLang = localStorage.getItem("portal-lang") || "zh";
    this.setState({
      lang: localLang,
    });

    window.router = this.router;
    this.router.history.listen((location) => {
      this.props.updataRouter(location.pathname);
      if (location.pathname === '/login' && this.state.isSmallScreen) {
        this.setState({visibleDrawer: false})
      }
    });
    this.props.updataRouter(window.location.pathname);
    this.props.getSiteInfo(window.location.host);
    // 校验token是否过期
    let token = getItem("token");
    if (
      window.router &&
      (!token || jwtDecode(token).exp < moment().valueOf() / 1000)
    ) {
      if (
        !["/login", "/reg", "/forget", "/404"].includes(
          window.location.pathname
        )
      )
        window.router.history.replace("login");
    } else {
      this.setState({ isLogin: true });
      this.props.getQualification();
      this.props.getSubject();
      this.props.getPriceByCompany();
      this.props.getTimeZones();
    }

    let timer = null
    // 屏幕小于900的，采用小屏幕展示
    const isSmallScreen = window.innerWidth < 900;
    this.setState({isSmallScreen})
    // 监听屏幕宽度变化
    window.onresize = () => {
      timer && clearTimeout(timer);

      timer = setTimeout(() => {
        // 屏幕小于900的，采用小屏幕展示
        const isSmallScreen = window.innerWidth < 900;
        this.setState({isSmallScreen})
      }, 500);
    }
  }
  closeChat = () => {
    this.setState({ collapse: !this.state.collapse });
  };
  changeLang = ({ key }) => {
    this.setState({
      lang: key,
    });
    localStorage.setItem("portal-lang", key);
  };
  clearLoc = () => {
    window.localStorage.removeItem("loc");
    window.localStorage.removeItem("loc.updatedAt");
    message.success("Location data has been successfully reset.");
    axios.get('https://www.cloudflare.com/cdn-cgi/trace').then((data) => {
        const loc = data.data.split("\n")[8].split("=")[1]
        window.localStorage.setItem("loc", loc)
        window.localStorage.setItem('loc.updatedAt', new Date().getTime())
        window.location.reload()
    })
  }

  handleCloseDrawer = () => {
    this.setState({
      visibleDrawer: !this.state.visibleDrawer,
    });
  };

  render() {
    const { curPath } = this.props;
    const { lang, visibleDrawer, isSmallScreen } = this.state;
    
    const fullPages = ['/login', '/reg', '/forget', '/404'];
    let isFullPages = fullPages.includes(curPath);

    return (
      <IntlProvider locale={lang} messages={messages[lang]}>
        <Router ref={(router) => (this.router = router)}>
          <PageContainer>
            <div className={cn("App", getToken().company)}>
              <div onClick={this.closeChat} style={{minWidth: '100%',height: '100%',minHeight: '100%', display: 'flex'}}>
                { !isFullPages && !isSmallScreen && <Menu collapsible={true}/> } 
                {isSmallScreen ? (
                    <Drawer
                      closable={false}
                      visible={visibleDrawer}
                      collapsible={false}
                      placement='left'
                      width={200}
                      bodyStyle={{
                        padding: 0,
                        minHeight: '100%',
                        backgroundColor: '#001529',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                      }}
                      onClose={this.handleCloseDrawer}>
                      <Menu collapsible={false}/>
                      {
                        isSmallScreen ? (
                          <div style={{marginBottom: 30, display: 'flex', flexDirection: 'column',alignItems: 'center'}}>
                            <CompanyOptions isSmallScreen={true}/>
                            <AgentsOptions isSmallScreen={true}/>
                            {
                              this.headerRef.current && this.headerRef.current.getWrappedInstance().renderUserInfo()
                            }
                          </div>
                        ) : null
                      }
                    </Drawer>
                  ) : null
                }
                <div
                  className={cn(
                    {'fullPage': isFullPages},
                    {'right-container': !isFullPages}
                  )} style={curPath === '/404' ? {overflow: 'hidden'} : null}>
                    { !isFullPages && <Header ref={this.headerRef} lang={lang} isSmallScreen={isSmallScreen} changeLang={this.changeLang} handleCloseDrawer={this.handleCloseDrawer}/> }
                    <Switch>
                      {
                        Routes.map((route, index) => <FancyRoute key={index} {...route}/>)
                      }
                      <Redirect to="/404"/>
                    </Switch>
                    {!isFullPages ? (
                        <span onDoubleClick={this.clearLoc} style={{
                          height: '10px',
                          lineHeight: '10px',
                          color: '#efefef',
                        }}>API: {baseURL}</span>
                     ) : null
                    }
                </div>
                {this.state.isLogin && this.props.studentPopupStatus ? (
                  <AddStudent />
                ) : null}
              </div>
              <ChatRoom collapse={this.state.collapse} />
            </div>
          </PageContainer>
        </Router>
      </IntlProvider>
    );
  }
}

const mapState = (models) => ({
  studentPopupStatus: models.common.studentPopupStatus,
  collapsed: models.common.collapsed,
  userName: models.userInfo.userName,
  curPath: models.common.curPath,
  company: models.userInfo.company,
});

const mapDispatch = (models) => ({
  updataRouter: (path) => models.common.updataRouter(path),
  getSubject: (path) => models.teacher.getSubject(path),
  getQualification: (path) => models.common.getQualification(path),
  getSiteInfo: (host) => models.common.getSiteInfo(host),
  getPriceByCompany: (host) => models.common.getPriceByCompany(host),
  getTimeZones: () => models.student.getTimeZones(),
});

export default connect(mapState, mapDispatch)(App);
