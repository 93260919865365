import * as server from '@/utils/api'
import { message } from 'antd'
import store from '../index'

const lang = window.localStorage.getItem('portal-lang')

const student = {
	state: {
        loading: false,
        list: [],
        mode: 'NORMAL',
        name: '',
        currentPage: 1,
        pageSize: 10,
        total: 0,
        timezones: []
	},
	reducers: {
    updateCommonState: (state, payload) => {
	    return { ...state, ...payload }
	  }
	},
	effects: {
    handleSomeState (payload) {
      this.updateCommonState(payload)
    },
    async getStudentList (payload, state) {
      let params = Object.assign({}, {
        limit: state.student.pageSize,
        page: state.student.currentPage,
        status: state.student.mode,
        name: state.student.name
      }, payload)
      this.updateCommonState({loading: true, list: []})
      let res = await server.getStudentList(params)
      if (res && res.status === 200) {
        let list = res.data.students || []
        let total = res.data.count || 0
        this.updateCommonState({list: list, total})
        if (state.student.mode === 'NORMAL' && !state.student.name) store.dispatch.common.updataTotalPage(total)
      }
      this.updateCommonState({loading: false})
      return res
    },
    async addStudent (payload) {
      let res = await server.addStudent(payload)
      if (res && res.status === 201) {
        message.success(lang === 'en' ? 'Added successfully' : '添加成功')
        this.getStudentList()
      }
      return res
    },
    async editStudent (payload) {
      let res = await server.editStudent(payload.id, payload.option)
      if (res && res.status === 202) {
        if (payload.option.status === 'PENDING_DELETE') {
          message.success(lang === 'en' ? 'Deleted successfully' : '删除成功')
        } else if (payload.option.status === 'NORMAL') {
          message.success(lang === 'en' ? 'Recovered successfully' : '恢复成功')
        } else {
          message.success(lang === 'en' ? 'Edited succesfully' : '修改成功')
        }
        this.getStudentList()
      }
      return res
    },
    async deleteStudent (payload) {
      let res = await server.deleteStudent(payload)
      if (res && res.status === 202) {
        message.success(lang === 'en' ? 'Deleted successfully' : '删除成功')
        this.getStudentList()
      }
    },
    exportClassTable (payload) {
        return server.exportClassTable(payload)
    },
    async getTimeZones () {
      let res = await server.getTimeZones()
      if (res && res.status === 200) {
          this.updateCommonState({timezones: res.data.timezones})
      }
    }
  }
}

export default student
