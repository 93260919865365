import React, { Component } from "react";
import { connect } from "react-redux";
import {
  UserOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Menu, Dropdown } from "antd";
import { getItem } from "@/utils/common";
import ResetPsd from "@/components/Staff/ResetPsd";
import { FormattedMessage } from "react-intl";
import { getToken } from "@/utils/common";
import AgentsOptions from '@/components/AgentsOptions'
import CompanyOptions from '@/components/CompanyOptions'

const titleMap = {
  "/": <FormattedMessage id="menu.course" />,
  "/course": <FormattedMessage id="menu.course" />,
  "/order": "订单",
  "/staff": <FormattedMessage id="menu.staff" />,
  "/student": <FormattedMessage id="menu.student" />,
  "/teacher": (
    <FormattedMessage
      id={
        getToken().company !== "Whetstone"
          ? "menu.teacher"
          : "menu.teacherWhetstone"
      }
    />
  ),
  "/statistic": <FormattedMessage id="statistic.pageTitle" />,
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      auth: "", // 当前用户权限
      agent: {}, // 当前用户信息
      companies: [],
      StandInAgents: [],
      resetPasswordShow: false,
    };
  }
  componentDidMount() {
    let auth = getItem("auth");
    let agent = getItem("agent") || {};
    this.setState({ auth, agent });
  }

  signOut = () => {
    localStorage.removeItem("agent");
    localStorage.removeItem("token");
    localStorage.removeItem("auth");
    this.props.closeWebSocket();
    window.router.history.replace("/login");
  };
  resetPassword = (status) => {
    this.setState({ resetPasswordShow: status });
  };
  getTitleByMap = (curpath) => {
    let pathName = curpath;
    for (let item of Object.keys(titleMap)) {
      if (item !== "/") {
        if (curpath.indexOf(item) === 0) {
          pathName = titleMap[item];
        }
      } else {
        pathName = titleMap[item];
      }
    }
    return pathName;
  };
  handleLanguage = (key) => {
    const { lang, changeLang } = this.props;
    if (key === lang) return;
    console.log(key, lang);
    changeLang({ key });
    window.location.reload();
  };

  renderUserInfo = () => {
    const { isSmallScreen } = this.props;
    const {
      agent,
    } = this.state;
    const { email: agent_email } = agent;

    const menu = (
      <Menu>
        <Menu.Item>
          <p
            style={{ textAlign: "center" }}
            onClick={() => this.resetPassword(true)}
          >
            <FormattedMessage id="common.changePassword" />
          </p>
        </Menu.Item>
        <Menu.Item>
          <p style={{ textAlign: "center" }} onClick={this.signOut}>
            <FormattedMessage id="common.signOut" />
          </p>
        </Menu.Item>
      </Menu>
    );

    return (
      <div className="right userInfo" style={{marginTop: isSmallScreen && 30}}>
        <Dropdown overlay={menu}>
          <span
            className="ant-dropdown-link"
            href="#"
            style={{
              color: isSmallScreen && '#fff',
            }}>
            {agent_email}
            <UserOutlined
              style={{ fontSize: 18, marginLeft: 5, verticalAlign: "middle" }}
            />
          </span>
        </Dropdown>
      </div>
    );
  };

  render() {
    const { curPath, totalPage, lang, isSmallScreen, handleCloseDrawer } = this.props;
    const {
      resetPasswordShow,
    } = this.state;

    return (
      <div className="header-container">
        <div className="right userInfo language" style={{ marginLeft: 40 }}>
          <span
            className={lang === "zh" ? "active" : ""}
            onClick={this.handleLanguage.bind(this, "zh")}
          >
            中文
          </span>
          <span style={{ cursor: "auto" }}>&nbsp;/&nbsp;</span>
          <span
            className={lang === "en" ? "active" : ""}
            onClick={this.handleLanguage.bind(this, "en")}
          >
            EN
          </span>
        </div>
        {!isSmallScreen && this.renderUserInfo()}
        {!isSmallScreen ? <AgentsOptions isSmallScreen={false}/> : null}
        {!isSmallScreen ? <CompanyOptions isSmallScreen={false}/> : null}
        <div className="page-title">
          {isSmallScreen ? (
              <MenuUnfoldOutlined
                style={{ fontSize: '20px', marginRight: 10 }}
                onClick={() => handleCloseDrawer()}/>
            ) : null
          }
          <span style={{verticalAlign: 'middle'}}>{this.getTitleByMap(curPath)} {totalPage ? `(${totalPage})` : ""}</span>
        </div>
        {resetPasswordShow ? (
          <ResetPsd cancel={() => this.resetPassword(false)} />
        ) : null}
      </div>
    );
  }
}

const mapState = (models) => ({
  curPath: models.common.curPath,
  totalPage: models.common.totalPage,
  stand_in: models.userInfo.stand_in,
  agent_email: models.userInfo.agent_email,
});

const mapDispatch = (models) => ({
  closeWebSocket: (data) => models.chatRoom.closeWebSocket(data),
});

export default connect(mapState, mapDispatch, null, {withRef: true})(Header);
