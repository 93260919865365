import { http } from '@/utils/request'
import { getItem } from '@/utils/common'
let jwtDecode = require('jwt-decode');


const getToken = () => {
    let token = getItem('token')
    return  token ? jwtDecode(token) : {}
}

const publicPath = '/v1/';

// 获取域名信息
export const getSiteInfo = (host) => http.get(publicPath + 'host', {host});
// 获取公司价格
export const getPriceByCompany = (host) => http.get(publicPath + `agent/${getToken().id}/company_price`, {host});

// 登录
export const login = (options) => http.post(publicPath + 'user/login', options);
// 发送送机验证码
export const sendSms = (options) => http.post(publicPath + 'user/phone/otp', options);
// 发送邮箱验证码
export const sendEmailCode = (options) => http.post(publicPath + 'user/email/send_reset', options);
// 注册
export const finishReg = (options) => http.post(publicPath + 'agent/0/reg', options);
// 忘记密码
export const passReset = (options) => http.post(publicPath + 'user/passreset', options);
// 忘记密码
export const resetPassword = (options) => http.put(publicPath + 'user/password', options);

// 公司列表
export const getAgentCompanies = (options) => http.get(publicPath + 'superuser/company', options);
// 超级用户变更公司
export const changeCompany = (options) => http.put(publicPath + 'superuser/company', {}, {params: options});
// 员工列表
export const getAgentList = (options) => http.get(publicPath + 'agent', options);
// 邀请员工
export const inviteAgent = (options) => http.post(publicPath + 'agent', options);
// 改变员工状态
export const editAgent = (id, options) => http.patch(publicPath + 'agent/' + id, options);
// 删除员工
export const deleteAgent = (id) => http.delete(publicPath + 'agent/' + id);

// 学生列表
export const getStudentList = (options) => http.get(publicPath + 'student', options);
// 添加学生
export const addStudent = (options) => http.post(publicPath + 'student', options);
// 改变学生状态
export const editStudent = (id, options) => http.patch(publicPath + 'student/' + id, options);
// 删除学生
export const deleteStudent = (id) => http.delete(publicPath + 'student/' + id);
// 获取学生课表文件
export const exportClassTable = (options) => http.get(publicPath + `agent/${getToken().id}/export/class_table`, options, {responseType: 'blob'});

// 老师列表
export const getTeachers = (options) => http.get(publicPath + 'teacher', options);
// 老师学校
export const getTeacherUniversity = (options) => http.get(publicPath + 'options/teacher_university', options);
// 科目列表
export const getSubject = (options) => http.get(publicPath + 'options/subject', options);
// 获取qualification
export const getQualification = (options) => http.get(publicPath + 'options/qualification', options);
// 获取工作经历
export const getTeacherJob = (options) => http.get(publicPath + 'teacher/' + options + '/qualification/job');
// 获取老师的信息
export const getTeacherInfoById = (options) => http.get(publicPath + `teacher/${options}`);
// 获取老师的评论
export const getTeacherReview = (options) => http.get(publicPath + `teacher/${options.id}/review`, options.params);
// 获取老师的空闲时间
export const getTeacherTimeIsFree = (id, options) => http.post(publicPath + `teacher/${id}/free`, options);
// 获取老师的课表时间
export const getTeacherTimetable = (options) => http.get(publicPath + `teacher/${options.id}/timetable`, options.params);
// 获取老师的最近两个月的非空闲时间
export const getTeacherUnavailableTimes= (options) => http.get(publicPath + `agent/${getToken().id}/teacher/unavailable_times/${options.id}`, options.params);

// 获取订单列表
export const getOrders = (options) => http.get(publicPath + 'agent/' + getToken().id + '/orders', options);
// 编辑课程
export const editOrder = (options) => http.patch(publicPath + `agent/${getToken().id}/orders/${options.id}/status`, options.params);
// 编辑课程s
export const editOrders = (options) => http.patch(publicPath + `agent/${getToken().id}/courses/status`, options.params);
// 创建通用订单
export const createGeneralOrders = (options) => http.post(publicPath + 'agent/' + getToken().id + '/orders', options);
// admin订单备注
export const admin_comment = (options) => http.post(publicPath + 'superuser/admin_comment/' + options.id, options.params);

// 获取课程列表
export const getCourses = (options) => http.get(publicPath + 'agent/g/courses', options);
// get all order of the same course id
export const getCourseById = (options) => http.get(`${publicPath}agent/${getToken().id}/courses/${options.id}`);
// 增加N节课
export const appendOrdersIntoCourse = (options) => http.post(`${publicPath}agent/g/courses/${options.course_id}`, options);
// 进入腾讯课堂
export const EnterClassroom = (options) => http.get(`${publicPath}agent/${getToken().id}/classedu/entry/${options.id}`);
// 获取腾讯课堂录制件返回列表
export const getClasseduRecord = (options) => http.get(`${publicPath}agent/${getToken().id}/classedu/record/${options.serial}`, options.params);

// 删除订单
export const deleteCourse = (id) => http.delete(publicPath + 'superuser/course/' + id);
export const deleteOrder = (id) => http.delete(publicPath + 'superuser/order/' + id);

// 获取时区列表
export const getTimeZones = () => http.get(publicPath + 'options/timezones');

// 翻译 XXX 服务器需要翻墙才能调用google翻译API
export const getTranslate = (options) => http.post(publicPath + 'translate/' + options.type, options);

// 统计页面 --- 导出接口
export const exportOrders = (options) => http.get(publicPath + `agent/${getToken().id}/export/orders`, options, {responseType: 'blob'});

// realtime loginInfo from tencent
export const getLoginInfo = (id) => http.get(publicPath + `agent/${getToken().id}/orders/global/logininfo?serial=${id}`);

// 助教评价接口
export const reviewByAgent = (options) => http.put(publicPath + `agent/${getToken().id}/order/${options.id}/review`, options.params);

// 检查当前是否有老师或学生身份的人在教室中
export const checkClassRoom = (id) => http.get(publicPath + `agent/${getToken().id}/courses_online_user/${id}`);

// 编辑课堂类型
export const changeClassType = (options) => http.patch(publicPath + `agent/${getToken().id}/courses/one_2_many/${options.id}/${options.status}`);
