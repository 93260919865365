import store from '../index';

import { getItem, topIndex } from '@/utils/common';
import { wsURL } from '@/utils/request';
import moment from 'moment';

let jwtDecode = require('jwt-decode');
let token = "",
    decoded = {};

const LIMIT = 30;
let WS_URL = `${wsURL}/v1/chat/ws`;
let lockReconnect = false;
let ws;
let tt,timeoutObj,serverTimeoutObj; // 计时器们
const TIMEOUT = 3000; // 心跳间隙
const MSG_CODE = require('../msg_code.json'); // 消息码
const initState = {
    page_timestamp: "",
    room_pages: "",
    ws_readyState: 0,
    active_room: "",
    last_msg: "",
    room_list: [],
    unread_msg_count: "",
    unread_msg_total: 0,
    unread_msg_student: 0,
    unread_msg_tutor: 0,
    is_unread_message: false,
    showExpire: false,
    showListByIdentity: 'COMMON',
    cacheMap: '',
    chat_members: {}
};
const chatRoom = {
    state: initState,
    reducers: {
        updateCommonState: (state, payload) => {
            return { ...state, ...payload }
        }
    },
    effects: {
        handleSomeState (payload) {
            this.updateCommonState(payload)
        },
        initSocket () {
            try {
                ws.onclose = () => {
                    this.updateCommonState({ws_readyState: WebSocket.CLOSED});
                };
                ws.onerror = () => {
                    this.updateCommonState({ws_readyState: WebSocket.CLOSED});
                    this.reconnect();
                };
                ws.onopen = () => {
                    //心跳检测重置
                    this.updateCommonState({ws_readyState: WebSocket.OPEN,initState});
                    this.heartCheck();
                };
                ws.onmessage = (event) => {
                    //拿到任何消息都说明当前连接是正常的
                    this.heartCheck();
                    if (!event.data) return;
                    let message;
                    try {
                        message = JSON.parse(event.data);
                    } catch (e) {
                        console.log('JSON ERR :',e);
                    }
                    if (!message || !message.code || message.code === MSG_CODE['heartCheck']) {
                        this.updateTimesStr();
                        return;
                    }
                    this.messageCenter(message);
                }
            } catch (e) {
                console.log('catch-init-socket--->', e);
                this.reconnect();
            }
        },
        createWebSocket() {
            token = getItem('token');
            decoded = token ? jwtDecode(token) : {};
            if (!token) return;
            try {
                ws = new WebSocket(`${WS_URL}?token=${token}`);
                this.initSocket();
            } catch(e) {
                console.log('catch=>',e);
                this.reconnect();
            }
        },
        closeWebSocket() {
            token = getItem('token');
            if (ws && ws.readyState !== WebSocket.CLOSED)
                ws.close();
            if (!token)
                this.updateCommonState(initState);
            ws = undefined;
            decoded = {};
        },
        collapseChatWindow(isOpen,state) {
            let { active_room, collapse_room } = state.chatRoom;
            if (isOpen) {
                this.updateCommonState({
                    collapse_room: "",
                });
                if (collapse_room)
                    this.changeActiveRoom(collapse_room);
            } else {
                this.updateCommonState({
                    active_room: "",
                    collapse_room: active_room,
                });
            }
        },
        reconnect() {
            this.updateCommonState({ws_readyState: WebSocket.CONNECTING});
            if(lockReconnect) return;
            lockReconnect = true;
            //没连接上会一直重连，设置延迟避免请求过多
            tt && clearTimeout(tt);
            tt = setTimeout( ()=> {
                this.createWebSocket();
                lockReconnect = false;
            }, TIMEOUT);
        },
        heartCheck(payload, state) {
            timeoutObj && clearTimeout(timeoutObj);
            serverTimeoutObj && clearTimeout(serverTimeoutObj);
            token = getItem('token');
            if (!token) {
                this.closeWebSocket();
                return;
            }
            timeoutObj = setTimeout( () => {
                if (!ws) return;
                // 更新连接状态
                // 0 (CONNECTING) 正在链接中 1 (OPEN) 已经链接并且可以通讯 2 (CLOSING) 连接正在关闭 3 (CLOSED) 连接已关闭或者没有链接成功
                if (ws.readyState !== state.chatRoom.ws_readyState) {
                    this.updateCommonState({ws_readyState: ws.readyState});
                }
                // 这里发送一个心跳，后端收到后，返回一个心跳消息
                if (ws.readyState === WebSocket.OPEN) ws.send(JSON.stringify({code: MSG_CODE['heartCheck']}));
                serverTimeoutObj = setTimeout( () => {
                    if (!ws) return;
                    if (WebSocket.CLOSING !== state.chatRoom.ws_readyState) {
                        this.updateCommonState({ws_readyState: WebSocket.CLOSING});
                    }
                    this.reconnect();
                }, TIMEOUT);
            }, TIMEOUT)
        },
        async messageCenter(message,state) {
            // console.log('message Center:',message);
            // 定义变量
            let message_window = document.getElementById("message-window");
            let { room_list, last_msg, active_room, room_pages, unread_msg_count, cacheMap} = state.chatRoom;
            
            if ([2,3,5].indexOf(parseInt(message.code/1000,10))>=0) {
                if ([5].indexOf(parseInt(message.code/1000,10)) < 0 && decoded.id !== message.author_id) this.unreadMessage(message.room_id);
                if (message.room_id === state.chatRoom.active_room) {
                    let message_window = document.getElementById("message-window");
                    let isForce = message_window.scrollHeight-message_window.scrollTop <= message_window.clientHeight + 20 || decoded.id===message.author_id;
                    this.scrollToEnd({isForce,message});
                }
            }
            switch (message.code) {
                case MSG_CODE['init']:
                    // 初始化房间聊天页码 map
                    this.setMembers(message.data.user_dict)
                    let room_pages_init = {}, cacheMap_init = {};
                    for(let item of message.data.room_list) {
                        room_pages_init[item.id]=0;
                        cacheMap_init[item.id]='';
                    }
                    this.updateCommonState({
                        unread_msg_count: message.data.unread_msg_count,
                        room_list: message.data.room_list,
                        last_msg: message.data.last_msg,
                        room_pages: room_pages_init,
                        cacheMap: !cacheMap ? cacheMap_init : cacheMap,
                        page_timestamp: message.data.timestamp,
                        active_room: '',
                    });
                    if (active_room)
                        this.changeActiveRoom(active_room);
                    this.calcUnreadTotal();
                    break;
                case MSG_CODE['addChat']:
                    // 加入聊天室
                    room_pages[message.room_id] = 0;
                    cacheMap[message.room_id] = '';
                    //更新成员
                    this.setMembers(message.data.user_dict)
                    this.updateCommonState({
                        unread_msg_count: Object.assign(unread_msg_count,message.data.unread_msg_count),
                        room_list: message.data.room_list.concat(room_list),
                        last_msg: Object.assign(last_msg,message.data.last_msg),
                        room_pages: room_pages,
                        cacheMap: cacheMap,
                    });
                    this.calcUnreadTotal();
                    break;
                case MSG_CODE['addMember']:
                    room_list.find((item, _index, arr) => {
                        let __is_active_room_index = item.id === message.room_id;
                        if (__is_active_room_index) {
                            arr[_index].teacher_ids = arr[_index].teacher_ids.concat(message.data.teacher_ids || []);
                            arr[_index].student_ids = arr[_index].student_ids.concat(message.data.student_ids || []);
                            arr[_index].agent_ids = arr[_index].agent_ids.concat(message.data.agent_ids || []);
                        }
                        return __is_active_room_index;
                    });
                    this.setMembers(message.data)
                    this.updateCommonState({
                        room_list: [].concat(room_list)
                    });
                    break;
                case MSG_CODE['changeExpire']:
                    let index = 0;
                    for (let i in room_list) {
                        if (room_list[i].id === message.room_id) {
                            index = i;
                            break;
                        }
                    }
                    room_list[index].expire_time = message.data.expire_time;
                    this.updateCommonState({room_list: [].concat(room_list)});
                    break;
                case MSG_CODE['word']:
                case MSG_CODE['img']:
                case MSG_CODE['file']:
                case MSG_CODE['mention']:
                case MSG_CODE['sysMsg']:
                    // 更新message
                    for(let item of last_msg[message.room_id]) {
                        if (item.id === message.id) return
                    }
                    last_msg[message.room_id].push(message);
                    last_msg = Object.assign({},last_msg);
                    this.updateCommonState({last_msg});
                    this.chatRoomToTop(message.room_id);
                    break;
                case MSG_CODE["moreMsg"]:
                    // 获取更多历史消息
                    /**
                     * 如果是第0页数据，更换last_msg的第一条数据
                     * 处理滚动到距离底部（当前滚动高度-当前滚动位置）
                     */
                    if (message.page.page !== room_pages[message.room_id])
                        return;
                    let from_bottom = message_window.scrollHeight-message_window.scrollTop;
                    if (room_pages[message.room_id] === 0) {
                        last_msg[message.room_id].shift();
                    }
                    if (message.data.length <= 0) {
                        room_pages[message.room_id] = 'no more';
                        break;
                    }
                    let messages = message.data.concat(last_msg[message.room_id]);
                    // 拼合消息
                    last_msg[message.room_id] = messages;
                    // 设置页码
                    room_pages[message.room_id]++;
                    await this.updateCommonState({
                        last_msg: Object.assign({},last_msg),
                        room_pages: Object.assign({},room_pages)
                    });
                    // 还原滚动位置
                    message_window.scrollTo({
                        top: message_window.scrollHeight - from_bottom,
                        behavior: "instant"
                    });
                    break;
                default:
                    store.dispatch.common.otherMessage(message);
                    break;
            }
        },
        setMembers(users, state) {
            if (!users) return
            let { chat_members } = state.chatRoom
            let __members = {...users.agent_dict, ...users.student_dict, ...users.teacher_dict}

            const getName = item => {
                return item? item.first_name?
                    item.first_name + (item.last_name ? " " : "") + item.last_name :
                    item.zh_name
                    : "Unknown user";
            };

            const getTalker = item => {
                if (item.id !== decoded.id) {
                    return getName(item)+':'
                } else {
                    return ''
                }
            }

            for (let i in __members) {
                let item = __members[i]
                item._role='agent'
                item._name=getName(item)
                item._talker = getTalker(item)
                __members[i] = item
            }
            chat_members = {...chat_members, ...__members}
            this.updateCommonState({chat_members});
        },
        isShowExpire(flag) {
            this.updateCommonState({showExpire: flag});
        },
        changeShowListByIdentity(value) {
            this.updateCommonState({showListByIdentity: value});
        },
        changeMessageCacheMap(value) {
            this.updateCommonState({cacheMap: value});
        },
        chatRoomToTop(id, state) {
            let room_list = state.chatRoom.room_list;
            let index = 0;
            for (let i in room_list) {
                let item = room_list[i];
                if (item.id === id) {
                    index = i;
                    break;
                }
            }
            room_list = topIndex(room_list,index);
            this.updateCommonState({room_list:[].concat(room_list)});
        },
        changeActiveRoom(id, state) {
            this.updateCommonState({
                active_room: id
            });
            this.scrollToEnd({isForce:true});
            if (state.chatRoom.room_pages[id]===0) {
                this.getMoreMessage();
            }
        },
        getMoreMessage(payload,state) {
            let { room_pages, active_room, page_timestamp} = state.chatRoom;
            if (room_pages[active_room] === 'no more')
                return;
            // 初始化时后端只提供一条last_msg
            // 当我更换正在活动房间时，获取第0页数据
            // then 处理 MSG_CODE["moreMsg"]的数据信息
            let data = {
                code: MSG_CODE["moreMsg"],
                room_id: active_room,
                data: {
                    timestamp: page_timestamp,
                },
                page: {
                    page: room_pages[active_room],
                    limit: LIMIT,
                }
            };
            if (ws && ws.readyState === WebSocket.OPEN) ws.send(JSON.stringify(data));
        },
        readMessage(message,state) {
            let {unread_msg_count, active_room} = state.chatRoom;
            // 如果当前没有可读消息 不通知服务端
            if (!unread_msg_count[active_room]) return;
            let data = {
                code: MSG_CODE["read"],
                room_id: active_room,
                data: {
                    timestamp: moment().valueOf(),
                }
            };
            if (ws && ws.readyState === WebSocket.OPEN) ws.send(JSON.stringify(data));
            unread_msg_count[active_room] = 0;
            this.updateCommonState({
                unread_msg_count: Object.assign({},unread_msg_count),
                is_unread_message: false
            });
            this.calcUnreadTotal();
        },
        unreadMessage(room_id,state) {
            let { unread_msg_count, active_room } = state.chatRoom;
            if (!unread_msg_count[room_id])
                unread_msg_count[room_id] = 0;
            unread_msg_count[room_id]++;
            this.updateCommonState({
                unread_msg_count:Object.assign({},unread_msg_count),
                is_unread_message: active_room === room_id
            });
            this.calcUnreadTotal();
        },
        scrollToEnd({isForce,message}, state){
            if (!message) {
                message = state.chatRoom.last_msg[state.chatRoom.active_room].slice(-1)[0];
            }
            let message_window = document.getElementById("message-window");
            if (isForce) {
                let behavior = message_window.scrollTop === 0 ? "instant" : "smooth";
                message_window.scrollTo({
                    top: message_window.scrollHeight,
                    behavior
                });
                this.readMessage(message);
            }
        },
        calcUnreadTotal(payload,state) {
            let unread_msg_total = 0;
            let unread_msg_student = 0;
            let unread_msg_tutor = 0;
            this.updateCommonState({unread_msg_total, unread_msg_student, unread_msg_tutor});
    
            let { unread_msg_count, room_list } = state.chatRoom;
            for (let key in unread_msg_count) {
                if (isNaN(Number(unread_msg_count[key]))) continue;
                unread_msg_total+=unread_msg_count[key];
                let chat_room_type = room_list.find(item=>item.id === key) ? room_list.find(item=>item.id === key).status : "";
                if ( chat_room_type === "COMMON" ) {
                    unread_msg_student+=unread_msg_count[key];
                }
                if ( chat_room_type === "INTERNAL" ) {
                    unread_msg_tutor+=unread_msg_count[key];
                }
            }
            this.updateCommonState({unread_msg_total, unread_msg_student, unread_msg_tutor});
        },
        async sendMessage(payload,state) {
            try {
                let sign = `${decoded.id}*${Math.random().toString(36).substr(2)}*${moment().valueOf()}`;
                let data = {
                    code: MSG_CODE[payload.type],
                    room_id: state.chatRoom.active_room,
                    value: payload.value,
                    sign
                };
                if (ws && ws.readyState === WebSocket.OPEN) {
                    await ws.send(JSON.stringify(data));
                    return true;
                } else {
                    return false
                }
            } catch (e) {
                return false;
            }
        },
        sendMention(payload,state) {
            let data = {
                code: MSG_CODE["mention"],
                room_id: state.chatRoom.active_room,
                data: {
                    users: payload.map(item=>JSON.parse(item))
                }
            };
            if (ws && ws.readyState === WebSocket.OPEN) ws.send(JSON.stringify(data));
        },
        updateTimesStr(payload,state) {
            // if (state.chatRoom.last_msg) {
            //     this.updateCommonState({last_msg: Object.assign({},state.chatRoom.last_msg)});
            // }
        },
        changeTranslateWord(payload,state) {
            let { last_msg, active_room } = state.chatRoom;
            let messageItem = last_msg[active_room].find(item => item.id === payload.messageId);
            messageItem.translate_word = payload.value
            this.updateCommonState({last_msg: Object.assign({},last_msg)});
        }
    }
};

export default chatRoom
