export default {
  "order.title":
    "指定的老师将会收到您的订单。在老师确认后，您的学生就可以按时间上课了",
  "order.bookingDesc": "预定 {name} 老师的课",
  "order.student": "学生",
  "order.classTime": "上课时间",
  "order.addClassTime": "添加上课时间",
  "order.classTimeTip": "该时间为不建议约课时间，老师可能会拒绝",
  "order.classTimeTipError": "该时间为不可约课时间",
  "order.classTimeTipHasClass": "该时间段老师有其他课程",
  "order.courseRequirements": "学生的要求(例如希望能顺便教大学物理)",
  "order.courseRequirementsTips":
    "学生的要求会在创建订单时会通过聊天室发送给教师",
  "order.courseRequirementsTipsTwo": "为了保障有效沟通，请使用英文与教师交流",
  "order.file": "文件(例如课程教材、问题等)",
  "order.file_Whetstone": "文件(例如课程教材、问题等)",
  "order.uploadFile": "上传文件",
  "order.supportedFiles": "支持扩展名：.rar .zip .doc .docx .pdf .jpg...",
  "order.modalTitle":
    "上课时间（设定持续周数，可以在每周的该时间固定上课。请选择学生所在时区，避免夏、冬令时产生时间调整）",
  "order.date": "日期",
  "order.week": "星期",
  "order.startTime": "开始时间",
  "order.classLength": "上课时长",
  "order.placeholder.duration": "时长",
  "order.placeholder.repeat": "周数",
  "order.weeksNumber": "持续周数",
  "order.selectDate": "请选择日期",
  "order.creatingDirectOrder": "创建指定老师订单",
  "order.confirmReschedule": "请确认修改后的上课时间",
  "order.currentlyRescheduling": "修改上课时间 {content}",
  "order.batchReschedule": "批量修改：",
  "order.length": "时长",
  "order.originalTime": "原时间",
  "order.endTime": "结束时间",
  "order.rescheduleSuccess": "重新约时间成功",
  "order.classDetail": "查看订单",
  "order.time": "上课时间",
  "order.teacher": "老师",
  "order.teacherLevel": "老师等级",
  "order.teacherGender": "老师性别",
  "order.highestEducationDegree": "老师最高学历",
  "order.teacherNationality": "老师国籍",
  "order.classBookingTimestamp": "提交时间",
  "order.academicRequirement": "学生要求",
  "order.filesEg": "文件(例如课程教材、问题等)",
  "order.classRecording": "课程视频",
  "order.classReport": "课堂反馈报告",
  "order.viewVideo": "查看视频",
  "order.viewClassReport": "查看反馈报告",
  "order.tipsModalTitle": "提示",
  "order.tipsModalContent":
    "因为这是一个追加课程，即使该老师没有接受订单，也不会分发给其他老师",
  "order.tipsModalBtn": "知道了",
  "order.tipsOther": "至少填写一个完整的时间段",
  "order.tipsConfirmSuccess": "确认成功",
  "order.cancelSucess": "取消成功",
  "order.tips": "第{number}条上课时间不完整",
};
