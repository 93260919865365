import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from "moment/moment";
import { Button, notification } from 'antd'

class SafeInputBox extends Component {
    constructor(props) {
        super(props);
        this.textArea = null;
        this.closeTip = null;
        this.closeTime = null;
        this.isControlKeysDown = false;
    }
    async componentDidMount() {
        // const textAreaDom = this.textArea;
        // textAreaDom.focus();
        let { activeRoom, cacheMap } = this.props;
        this.textArea.innerHTML = cacheMap[activeRoom] || '';
        // 光标移动到最后
        // this.textArea.focus();  只会移动到第0位
        let sel = window.getSelection();
        let range = document.createRange();
        range.selectNodeContents(this.textArea);
        range.collapse(false);
        sel.removeAllRanges();
        sel.addRange(range);
    }
    shouldComponentUpdate() {
        // 永远不自动重新渲染, 防止输入框被刷新
        return false;
    }
    async componentWillReceiveProps(nextProps) {
        if (nextProps.closeTime<=0) {
            this.textArea.blur();
        }
        if (nextProps.closeTime>=3600000 || nextProps.closeTime<=0) {
            this.closeTip.className = "close-time none";
        } else {
            this.closeTip.className = "close-time";
            this.closeTime.innerHTML = moment(nextProps.closeTime).format("mm:ss")
        }
        let { activeRoom, cacheMap } = this.props;
        if (nextProps.activeRoom && nextProps.activeRoom !== activeRoom ) {
            this.textArea.innerHTML = cacheMap[nextProps.activeRoom] || '';
            // 光标移动到最后
            // this.textArea.focus();  只会移动到第0位
            let sel = window.getSelection();
            let range = document.createRange();
            range.selectNodeContents(this.textArea);
            range.collapse(false);
            sel.removeAllRanges();
            sel.addRange(range);
        }
    }

    sendWord = async () => {
        let { cacheMap, activeRoom } = this.props;
        let word = cacheMap[activeRoom].trim();

        let finished = await this.props.sendWord(word);
        if (finished) {
            cacheMap[activeRoom] = "";
            this.textArea.innerHTML = cacheMap[activeRoom] || '';
            this.props.changeMessageCacheMap(cacheMap);
        } else {
            notification.close('warning');
            const args = {
                message: '⚠️Warning: Message wasn\'t delivered',
                description: word,
                placement: 'bottomRight',
                duration: 5,
                key: 'warning'
            };
            notification.open(args);
        }
    };
    listenInputDown = async event => {
        // 监听回车
        if ([91,92,93,17,18].indexOf(event.keyCode) >= 0 ) {
            this.isControlKeysDown = true;
        }
        // Shift + Enter 正常换行
        if (event.keyCode === 13) {
            if(event.preventDefault) event.preventDefault();  //标准技术
            if(event.returnValue) event.returnValue = false;  //IE
            if (!event.shiftKey) {
                this.sendWord();
            } else {
                // 控制光标
                const selection = window.getSelection();
                // 删除选中区域内容，替换插入的内容
                selection.deleteFromDocument();
                // 获取首个选中区域
                let range = selection.getRangeAt(0);
                // 插入粘贴的文本内容
                let beforeH = this.textArea.scrollHeight;
                let TextNode = document.createTextNode("\r\n");
                range.insertNode(TextNode);
                let afterH = this.textArea.scrollHeight;
                if (beforeH === afterH) {
                    let TextNodeAfter = document.createTextNode("\r\n");
                    range.insertNode(TextNodeAfter);
                }
                // 折叠选中区域的光标位置到选中区域末端
                selection.collapseToEnd();
            }
        }
    };
    listenInputUp = event => {
        // 设置房间的文字缓存
        if ([91,92,93,17,18].indexOf(event.keyCode) >= 0 ) {
            // 如果是 CTRL window command等功能键 不处理
            this.isControlKeysDown = false;
            return;
        }
        if (this.isControlKeysDown) return;
        this.setCacheMap();
        // 滚动到合适位置
        if ([37,38,39,40].indexOf(event.keyCode) < 0 ) {
            let topPosition = this.getCaretYPosition();
            this.textArea.scrollTo({
                top: topPosition,
                behavior: "instant"
            });
        }
    };
    // 获取光标位置
    getCaretYPosition = () => {
        let sel = window.getSelection();
        let range = sel.getRangeAt(0);
        let span = document.createElement('span');
        span.innerText='·';
        range.collapse(false);
        range.insertNode(span);
        let topPosition = span.offsetTop;
        span.parentNode.removeChild(span);
        return topPosition;
    };
    setCacheMap = async () => {
        // 设置房间的文字缓存
        let { cacheMap, activeRoom } = this.props;
        cacheMap[activeRoom] = this.textArea.innerHTML || '';
        await this.props.changeMessageCacheMap(cacheMap);
    };
    handlePaste = (event) => {
        // 重新定义粘贴文本的处理方式
        // 清除粘贴样式，只保留文字
        let paste = (event.clipboardData || window.clipboardData).getData('text');
        // 控制光标
        const selection = window.getSelection();
        // rangeCount 光标选择区域个数  如果不存在不处理
        if (!selection.rangeCount) return false;
        // 删除选中区域内容，替换插入的内容
        selection.deleteFromDocument();
        // 获取首个选中区域
        let range = selection.getRangeAt(0);
        // 插入粘贴的文本内容
        range.insertNode(document.createTextNode(paste));
        // 折叠选中区域的光标位置到选中区域末端
        selection.collapseToEnd();
        // 取消粘贴的默认操作
        if(event.preventDefault) event.preventDefault();  //标准技术
        if(event.returnValue) event.returnValue = false;  //IE
        this.setCacheMap();
    };
    BlurToInput = () => {
      this.textArea.focus();
    };
    render() {
        //viewing_snacks_state_pop_box
        return (
            <div className="input-area-view"
                 onClick={this.BlurToInput} >
                <div ref={(ref) => this.textArea=ref}
                     onPasteCapture={this.handlePaste}
                     contentEditable="true"
                     onKeyDown={this.listenInputDown}
                     onKeyUp={this.listenInputUp}
                     placeholder="Use Shift + Enter key for line change" />
                <div className="btn-view">
                    <span ref={(ref) => this.closeTip=ref} className={"close-time"}>
                        The chat room will be closed in <i ref={(ref) => this.closeTime=ref} />!
                    </span>
                    <Button onClick={this.sendWord}>Send</Button>
                </div>
            </div>
        );
    }
}

const mapState = models => ({
    cacheMap: models.chatRoom.cacheMap,
});

const mapDispatch = models => ({
    changeMessageCacheMap: (payload) => models.chatRoom.changeMessageCacheMap(payload),
});

export default connect(mapState, mapDispatch)(SafeInputBox)