import { login, sendSms, finishReg, sendEmailCode, passReset, resetPassword } from '@/utils/api'
import { message } from 'antd'

const lang = window.localStorage.getItem('portal-lang')

const userInfo = {
	state: {
		userName: '',
    agent: {},
	},
	reducers: {
    updateCommonState: (state, payload) => {
      return { ...state, ...payload }
	  }
	},
	effects: {
    async login (payload, state) {
      let res = await login(payload)
      return res
    },
    async regAgent (payload, state) {
      let res = await finishReg(payload)
      return res
    },
    async passReset (payload, state) {
      let res = await passReset(payload)
      return res
    },
    async resetPassword (payload, state) {
      return await resetPassword(payload);
    },
    async sendSms (payload, state) {
      let res = await sendSms(payload)
      if (res && res.status === 200) {
        message.success(lang === 'en' ? 'Verification code sent' : '验证码已发送')
      }
      return res
    },
    async sendEmailCode (payload, state) {
      let res = await sendEmailCode(payload)
      if (res && res.status === 200) {
        message.success(lang === 'en' ? 'Verification code sent' : '验证码已发送')
      }
      return res
    }
  }
}

export default userInfo
