import React, { Component } from "react";
import { connect } from "react-redux";
import {
  CloseCircleOutlined,
  SkinOutlined,
} from "@ant-design/icons";
import { Select } from "antd";
import { getItem } from "@/utils/common";

const { Option } = Select;

class AgentsOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: "", // 当前用户权限
      agent: {}, // 当前用户信息
      StandInAgents: [],
    };
  }
  componentDidMount() {
    let auth = getItem("auth");
    let agent = getItem("agent") || {};
    this.setState({ auth, agent });
    if (auth === "admin") {
      this.getAgentListByStandIn();
    }
  }
  getAgentListByStandIn() {
    this.props.changeStandIn('');
    this.props.getAgentListByStandIn().then((res) => {
      if (res && res.status === 200) {
        let agents = res.data.agents || [];
        this.setState({
          StandInAgents: agents.filter((item) => item.registered === true),
        });
      }
    });
  }
  changeStandIn = async (value) => {
    this.props.changeStandIn(value ? {
      id: value.value,
      zh_name: value.label
    } : '');
  };

  render() {
    const { isSmallScreen, stand_in_agent } = this.props
    const { auth, agent, StandInAgents } = this.state;
    const { zh_name: agent_name } = agent;

    if (stand_in_agent) {
      stand_in_agent.label = stand_in_agent.zh_name;
      stand_in_agent.value = stand_in_agent.id;
    }

    return auth === "admin" ? (
      <div className="right userInfo" style={{width: 150, marginRight: !isSmallScreen && 15}}>
        <Select
          showSearch
          allowClear
          value={stand_in_agent || {
            value: agent?.id,
            label: agent_name,
          }}
          style={{ width: '100%' }}
          optionFilterProp="children"
          suffixIcon={
            <SkinOutlined style={{ fontSize: 18, marginTop: "-3px" }} />
          }
          clearIcon={
            <CloseCircleOutlined
              style={{
                fontSize: 18,
                margin: "-3px 0 0 -5px",
                backgroundColor: "#FFF",
              }}
            />
          }
          dropdownMatchSelectWidth={false}
          labelInValue
          onChange={this.changeStandIn}
          filterOption={(input, option) => 
            option.children
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0            
          }
        >
          {StandInAgents.map((item, index) => (
            <Option
              key={`${item.id}_${index}`}
              value={item.id}
            >
              {item.zh_name}
            </Option>
          ))}
        </Select>{" "}
        &emsp;
      </div>
    ) : null;
  }
}

const mapState = (models) => ({
  stand_in_agent: models.auth.stand_in_agent,
}); 

const mapDispatch = (models) => ({
  getAgentListByStandIn: (data) => models.auth.getAgentListByStandIn(data),
  changeStandIn: (data) => models.auth.changeStandIn(data),
});

export default connect(mapState, mapDispatch)(AgentsOptions);
