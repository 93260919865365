import React, { Component } from "react";
import { connect } from "react-redux";
import {
  // Button,
  Upload,
  Progress,
  message,
  // Popover,
  // Checkbox,
  // Icon
} from "antd";
import { baseURL } from "@/utils/request";
import { getItem } from "@/utils/common";
import moment from "moment";
import cn from "classnames";

import SafeInputBox from "./SafeInputBox";

// const CheckboxGroup = Checkbox.Group;
// const emojiIco = require("@/assets/images/emoji-icon.png");
const fileIco = require("@/assets/images/file-icon.png");
// const atIco = require("@/assets/images/at-icon.png");
// const inviteIco = require("@/assets/images/invite-icon.png");
const ROLE = require("@/store/urlConfig.json")["role"];

const jwtDecode = require("jwt-decode");
let token;
let decoded;

let IntervalTimer = null;
class InputArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      percent: 0,
      loading: false,
      loading_status: "normal",
      closeTime: 86400000,
      activeRoomData: "",
      users: [],
      checkedList: [],
      indeterminate: false,
      checkAll: false,
      visible: false,
    };
  }
  shouldComponentUpdate(nextProps, nextState) {
    return nextState !== this.state;
  }
  async componentWillReceiveProps(nextProps) {
    await this.getActiveRoomData(nextProps);
    this.getCloseTimeStr();
    this.setUsers();
  }
  async componentDidMount() {
    await this.getActiveRoomData(this.props);
    this.getCloseTimeStr();
    this.setUsers();
    IntervalTimer = setInterval(() => {
      this.getCloseTimeStr();
    }, 1000);
  }
  componentWillUnmount() {
    if (IntervalTimer) clearInterval(IntervalTimer);
  }
  setUsers = () => {
    let { activeRoomData } = this.state;
    let { chat_members } = this.props;
    // data.agents data.students data.teachers
    let getUserByRole = (usersByRole, role) => {
      let users_arr_item = [];
      for (let item of usersByRole) {
        if (item === decoded.id) continue;
        users_arr_item.push({
          label: chat_members[item]?._name,
          value: JSON.stringify({
            id: item,
            role: chat_members[item]?._role,
            name: chat_members[item]?._name,
          }),
        });
      }
      return users_arr_item;
    };
    let agents = getUserByRole(activeRoomData.agent_ids);
    let students = getUserByRole(activeRoomData.student_ids);
    let teachers = getUserByRole(activeRoomData.teacher_ids);
    let users = teachers.concat(students, agents);
    this.setState({ users });
  };
  getActiveRoomData = async (_props) => {
    if (_props.room_list.length <= 0) {
      return "";
    }
    let activeRoomData =
      _props.room_list.find((item) => {
        return item.id === _props.active_room;
      }) || "";
    await this.setState({ activeRoomData });
  };
  getCloseTimeStr = () => {
    let closeTime = -moment().diff(this.state.activeRoomData.expire_time);
    this.setState({ closeTime });
  };
  sendWord = async (word) => {
    if (word.trim() === "") return;
    let finished;
    finished = await this.props.sendMessage({ type: "word", value: word });
    return finished;
  };
  // sendAt = () => {
  //     if (!this.state.checkedList || this.state.checkedList.length <= 0) return;
  //     this.props.sendMention(this.state.checkedList);
  //     this.setState({visible: false});
  // };
  beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 50;
    if (!isLt2M) {
      message.error("File must be smaller than 50MB!");
    }
    return isLt2M;
  };
  /**
   * {file,fileList,event}
   */
  handleChange = ({ file }) => {
    if (file.status === "done") {
      let isImg = /image\/\w+/.test(file.type);
      let timer = setTimeout(() => {
        this.setState({
          loading: false,
          percent: 0,
        });
        clearTimeout(timer);
      }, 1000);
      // let list = fileList.map(item => item.response.url);
      this.props.sendMessage({
        type: isImg ? "img" : "file",
        value: `${file.response.url}?${file.size}`,
      });
    } else if (file.status === "uploading") {
      this.setState({
        percent: Number(parseFloat(file.percent).toFixed(1)),
        loading: true,
      });
    } else if (file.status === "error" || file.status === "removed") {
      this.setState({
        loading_status: "exception",
        percent: 98,
      });
      let timer = setTimeout(() => {
        this.setState({
          loading: false,
          percent: 0,
          loading_status: "normal",
        });
        clearTimeout(timer);
      }, 3000);
    }
  };
  // handleVisibleChange = visible => {
  //     this.setState({ visible });
  // };
  // onChangeMention = (checkedList) => {
  //     this.setState({
  //         checkedList,
  //         indeterminate: !!checkedList.length && (checkedList.length < this.state.users.length),
  //         checkAll: checkedList.length === this.state.users.length,
  //     });
  // };
  //
  // onCheckAllChangeMention = (e) => {
  //     this.setState({
  //         checkedList: e.target.checked ? this.state.users.map(item=>item.value) : [],
  //         indeterminate: false,
  //         checkAll: e.target.checked,
  //     });
  // };
  render() {
    token = getItem("token");
    decoded = token ? jwtDecode(token) : {};
    const Toolbar = (
      <div className="toolbar">
        {/*<div className="tool-item left">*/}
        {/*<img src={emojiIco} alt=""/>*/}
        {/*</div>*/}
        <div className="tool-item left">
          <Upload
            listType="picture"
            showUploadList={false}
            showRemoveIcon={true}
            action={`${baseURL}/v1/${ROLE}/${decoded.id}/files`}
            headers={{
              authorization: `Bearer ${token}`,
            }}
            beforeUpload={this.beforeUpload}
            onChange={this.handleChange}
          >
            <img src={fileIco} alt="" />
          </Upload>
          {this.state.loading && (
            <Progress
              type="circle"
              width={50}
              strokeWidth={10}
              className={"upload-progress"}
              percent={this.state.percent}
              status={this.state.loading_status}
            />
          )}
        </div>
        {/*<div className="tool-item left">*/}
        {/*<Popover placement="topLeft"*/}
        {/*title={*/}
        {/*<div style={{margin: '10px 0'}}>*/}
        {/*<Checkbox indeterminate={this.state.indeterminate}*/}
        {/*onChange={this.onCheckAllChangeMention}*/}
        {/*checked={this.state.checkAll}>*/}
        {/*Check all*/}
        {/*</Checkbox>*/}
        {/*<Button className={"right"}*/}
        {/*size={"small"}*/}
        {/*type="primary"*/}
        {/*onClick={this.sendAt}>*/}
        {/*<Icon type={"shake"} />Send reminder*/}
        {/*</Button>*/}
        {/*</div>*/}
        {/*}*/}
        {/*content={*/}
        {/*<div style={{ width: '400px', maxHeight: "240px", overflow: "scroll"}}>*/}
        {/*<CheckboxGroup options={this.state.users}*/}
        {/*value={this.state.checkedList}*/}
        {/*onChange={this.onChangeMention} />*/}
        {/*</div>*/}
        {/*}*/}
        {/*visible={this.state.visible}*/}
        {/*onVisibleChange={this.handleVisibleChange}*/}
        {/*trigger={"click"}>*/}
        {/*<img src={atIco} alt=""/>*/}
        {/*</Popover>*/}
        {/*</div>*/}
        {/*<div className="tool-item right">*/}
        {/*<img src={inviteIco} alt=""/>*/}
        {/*</div>*/}
      </div>
    );
    return (
      <div
        className={cn("input-area", {
          "masking-layer": this.state.closeTime <= 0,
        })}
      >
        {Toolbar}
        <SafeInputBox
          activeRoom={this.props.active_room}
          closeTime={this.state.closeTime}
          sendWord={this.sendWord}
        />
      </div>
    );
  }
}

const mapState = (models) => ({
  active_room: models.chatRoom.active_room,
  room_list: models.chatRoom.room_list,
  chat_members: models.chatRoom.chat_members,
});

const mapDispatch = (models) => ({
  //send message to server by type
  sendMessage: (payload) => models.chatRoom.sendMessage(payload),
  sendMention: (payload) => models.chatRoom.sendMention(payload),
});

export default connect(mapState, mapDispatch)(InputArea);
