import { init } from '@rematch/core'

import auth from './modules/auth'
import userInfo from './modules/userInfo'
import common from './modules/common'
import agent from './modules/agent'
import student from './modules/student'
import teacher from './modules/teacher'
import order from './modules/order'
import course from './modules/course'
import chatRoom from './modules/chatRoom'
import chatView from './modules/chatView'
import statistic from './modules/statistic'

const store = init({
	models: {
    auth,
    userInfo,
    common,
    agent,
    student,
    teacher,
    order,
    course,
    chatRoom,
    chatView,
    statistic,
	}
});
export default store
