export default {
    "staff.title": 'Here you can manage all of your current team members and can invite new ones',
    "staff.department": 'Department/Title',
    "staff.unregistered": 'Unregistered',
    "staff.registered": 'Registered',
    "staff.inviteNewMember": 'Invite New Member',
    "staff.editMember": 'Edit Member',
    "staff.invite": 'Invite',
    "staff.inviteTitle": 'Are you sure you want to invite {name} ？',
    "staff.submitInvitation": 'Submit Invitation',
    "staff.adminPrivileges": 'Admin Privileges',
    "staff.updateSuccess": 'Password successfully updated',
};