import React, { Component } from "react";
import { connect } from "react-redux";
import { LockOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Input, Modal, Button, message } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";

const FormItem = Form.Item;

class ResetPsd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  submitForm = () => {
    const form = this.props.form;
    form.validateFields((err, values) => {
      console.log(err, values);
      if (!err) {
        this.setState({ loading: true });
        this.props
          .resetPassword({
            password: values.newPassword,
            old_password: values.currentPassword,
          })
          .then((res) => {
            if (res && res.status === 200) {
              message.success(
                this.props.intl.formatMessage({ id: "staff.updateSuccess" })
              );
              window.router.history.replace("/login");
            } else {
              message.error(res.message);
            }
            this.setState({ loading: false });
          });
      }
    });
  };

  closeModal = () => {
    this.props.cancel();
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue("newPassword")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <Modal
        title={<FormattedMessage id="common.changePassword" />}
        maskClosable={false}
        visible={true}
        onCancel={this.closeModal}
        footer={[
          <Button key="back" onClick={this.closeModal}>
            <FormattedMessage id="common.cancel" />
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={this.state.loading}
            onClick={this.submitForm}
          >
            <FormattedMessage id="common.confirm" />
          </Button>,
        ]}
      >
        <Form>
          <FormItem
            {...formItemLayout}
            label={<FormattedMessage id="common.currentPassword" />}
          >
            {getFieldDecorator("currentPassword", {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage id="common.tips.pleaseEnterCurrentPassword" />
                  ),
                },
              ],
            })(<Input type="password" prefix={<LockOutlined />} />)}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={<FormattedMessage id="common.newPassword" />}
          >
            {getFieldDecorator("newPassword", {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage id="common.tips.pleaseEnterNewPassword" />
                  ),
                },
              ],
            })(<Input type="password" prefix={<LockOutlined />} />)}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={<FormattedMessage id="common.confirmPassword" />}
          >
            {getFieldDecorator("confirmPassword", {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage id="common.tips.pleaseConfirmYourPassword" />
                  ),
                },
                { validator: this.compareToFirstPassword },
              ],
            })(<Input type="password" prefix={<LockOutlined />} />)}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

const ResetPsdForm = Form.create()(ResetPsd);

const mapState = (state) => state;

const mapDispatch = (models) => ({
  resetPassword: (data) => models.userInfo.resetPassword(data),
});

export default injectIntl(connect(mapState, mapDispatch)(ResetPsdForm));
