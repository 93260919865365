import * as server from '@/utils/api'

const auth = {
    state: {
        stand_in_agent: ""
    },
    reducers: {
        updateCommonState: (state, payload) => {
            return { ...state, ...payload }
        }
    },
    effects: {
        async getAgentCompanies (payload) {
            let res = await server.getAgentCompanies(payload);
            return res;
        },
        async changeCompany (payload) {
            let res = await server.changeCompany(payload);
            return res;
        },
        async getAgentListByStandIn () {
            let res = await server.getAgentList({
                limit: 9999,
                page: 1,
                status: 'NORMAL'
            });
            return res;
        },
        changeStandIn (payload) {
            this.updateCommonState({stand_in_agent: payload})
        }
    }
};

export default auth
