import React from "react";
import { Route } from "react-router-dom";
// 页面加载进度条
import nprogress from "nprogress";
import "nprogress/nprogress.css";

import { getItem } from "@/utils/common";

class FancyRoute extends React.Component {
  componentDidMount() {
    // 校验token是否过期
    let token = getItem("token");
    if (
      window.router &&
      !token &&
      !["/login", "/reg", "/forget"].includes(window.location.pathname)
    ) {
      window.router.history.push("login");
      return;
    }
    nprogress.start();
  }

  componentDidMount() {
    nprogress.done();
  }

  render() {
    return <Route {...this.props} />;
  }
}

export default FancyRoute;
