import React from "react";
import { connect } from "react-redux";
import { Card, Badge, Radio, Input } from "antd";
import { formatTime, getItem } from "@/utils/common";
import cn from "classnames";
import moment from "moment/moment";

import GroupAvatar from "./GroupAvatar";

const MSG_CODE = require("@/store/msg_code.json"); // 消息码
const ROLE = require("@/store/urlConfig.json").role;
let tabTitle = { agent: "Tutor", teacher: "Consultant" }[ROLE];
let jwtDecode = require("jwt-decode");

const { Search } = Input;
const { Meta } = Card;
const bodyStyle = {
  padding: "0 20px 10px",
};
let token;
let decoded;

const GroupList = (props) => {
  token = getItem("token");
  decoded = token ? jwtDecode(token) : {};

  const getMemberByGroup = (groupItem) => {
    if (!groupItem) return { members: {}, members_arr: [] };
    let members = props.chat_members;
    let members_arr = [].concat(
      groupItem.agent_ids,
      groupItem.student_ids,
      groupItem.teacher_ids
    );
    members_arr = members_arr
      .filter((item) => members[item])
      .map((item) => members[item]);
    return { members, members_arr };
  };

  let {
    room_list,
    unread_msg_count,
    active_room,
    last_msg,
    searchChatText,
  } = props;
  room_list = room_list.filter(
    (item) =>
      moment().diff(item.expire_time) > 0 === props.showExpire &&
      item.status === props.showListByIdentity &&
      (item.title + item.alias_name)
        .toLocaleLowerCase()
        .indexOf(searchChatText.toLocaleLowerCase()) >= 0
  );

  return (
    <div
      className={cn("group-list", { "expire-tip-space": props.showExpire })}
      id={"group-list"}
    >
      <div className="switch-expire">
        <Radio.Group
          className={"type-tab-identity"}
          onChange={(e) => {
            props.changeShowListByIdentity(e.target.value);
            document.getElementById("group-list").scrollTo({
              top: 0,
              behavior: "instant",
            });
          }}
          defaultValue={props.showListByIdentity}
          buttonStyle="solid"
        >
          <Radio.Button value={"COMMON"}>
            Students
            <Badge dot count={props.unread_msg_student} />
          </Radio.Button>
          <Radio.Button value={"INTERNAL"}>
            {tabTitle}
            <Badge dot count={props.unread_msg_tutor} />
          </Radio.Button>
        </Radio.Group>
        <div className={"type-tab-expire"}>
          <p
            onClick={() => {
              props.isShowExpire(false);
            }}
            className={cn({ active: !props.showExpire })}
          >
            <span>Current</span>
          </p>
          <p
            onClick={() => {
              props.isShowExpire(true);
            }}
            className={cn({ active: props.showExpire })}
          >
            <span>Completed</span>
          </p>
        </div>
        <marquee className={cn("expire-tip", { none: !props.showExpire })}>
          This course is already finished,nothing to say here!
        </marquee>
      </div>

      {room_list.length > 0 || searchChatText ? (
        <Search
          placeholder="search"
          allowClear
          value={searchChatText}
          onChange={(e) => props.searchChat(e.target.value)}
        />
      ) : null}
      {room_list.length > 0 ? (
        room_list.map((item, index) => {
          let { members, members_arr } = getMemberByGroup(item);
          let end_msg = last_msg[item.id][last_msg[item.id].length - 1] || {};
          return (
            <div
              key={index}
              className={"group-item-view"}
              onClick={() => props.changeActiveRoom(item.id)}
            >
              <Card
                className={cn(
                  "group-item",
                  { "group-item-active": item.id === active_room },
                  {
                    "group-item-expire": -moment().diff(item.expire_time) <= 0,
                  },
                  {
                    none:
                      -moment().diff(item.expire_time) > 0 === props.showExpire,
                  },
                  { none: item.status !== props.showListByIdentity }
                )}
                extra={
                  <div>
                    <p className={"left group-count"}>({members_arr.length})</p>
                    <Badge
                      style={{ marginTop: "-18px" }}
                      count={unread_msg_count[item.id]}
                    />
                  </div>
                }
                bordered={false}
                bodyStyle={bodyStyle}
                headStyle={{ border: 0 }}
                title={
                  <Meta
                    avatar={
                      <div
                        style={{
                          width: "40px",
                          height: "40px",
                          fontSize: "40px",
                        }}
                      >
                        <GroupAvatar
                          data={members_arr.map((item, index) => {
                            if (
                              item.id !== decoded.id ||
                              members_arr.length > 2
                            ) {
                              return item.extra && item.extra.profile_pic ? (
                                <img
                                  key={index}
                                  className={"avatar"}
                                  alt=""
                                  src={`${item.extra.profile_pic}?x-oss-process=style/thumbnail`}
                                />
                              ) : (
                                <div
                                  key={index}
                                  className={`avatar chat-room-user-avatar ${item._role}`}
                                >
                                  {item._name.substring(0, 1).toUpperCase()}
                                </div>
                              );
                            }
                            return null;
                          })}
                        />
                      </div>
                    }
                    title={
                      <div>
                        <p className={"ellipsis"}>
                          {item.title}
                          {item.alias_name && `-${item.alias_name}`}
                        </p>
                        <p className={"moment-from-now ellipsis"}>
                          {formatTime(end_msg.created_at || 0)}
                        </p>
                      </div>
                    }
                  />
                }
              >
                <div className={"ellipsis"}>
                  {end_msg.author_id &&
                    end_msg.code !== MSG_CODE["mention"] &&
                    end_msg.code !== MSG_CODE["sysMsg"] && (
                      <span>
                        {members[end_msg.author_id]
                          ? members[end_msg.author_id]._talker
                          : "Unknown user: "}
                      </span>
                    )}

                  {end_msg.code === MSG_CODE["img"] && (
                    <div className={"word"}>[Image Message]</div>
                  )}
                  {end_msg.code === MSG_CODE["file"] && (
                    <div className={"word"}>[File Message]</div>
                  )}
                  {end_msg.code === MSG_CODE["word"] && (
                    <div
                      className={"word"}
                      dangerouslySetInnerHTML={{ __html: end_msg.value }}
                    />
                  )}
                  {(end_msg.code === MSG_CODE["mention"] ||
                    end_msg.code === MSG_CODE["sysMsg"]) && (
                    <div className={"word"}>[system]: {end_msg.value}</div>
                  )}
                </div>
              </Card>
            </div>
          );
        })
      ) : (
        <p className={"tip"}>
          You haven't joined <br /> any chat rooms of this type yet.
        </p>
      )}
    </div>
  );
};

const mapState = (models) => ({
  last_msg: models.chatRoom.last_msg,
  room_list: models.chatRoom.room_list,
  unread_msg_student: models.chatRoom.unread_msg_student,
  unread_msg_tutor: models.chatRoom.unread_msg_tutor,
  unread_msg_count: models.chatRoom.unread_msg_count,
  active_room: models.chatRoom.active_room,
  showExpire: models.chatRoom.showExpire,
  showListByIdentity: models.chatRoom.showListByIdentity,
  chat_members: models.chatRoom.chat_members,
  searchChatText: models.chatView.searchChatText,
});

const mapDispatch = (models) => ({
  //change active room and clear unread message
  changeActiveRoom: (id) => models.chatRoom.changeActiveRoom(id),
  isShowExpire: (flag) => models.chatRoom.isShowExpire(flag),
  changeShowListByIdentity: (flag) =>
    models.chatRoom.changeShowListByIdentity(flag),
  searchChat: (text) => models.chatView.searchChat(text),
});

export default connect(mapState, mapDispatch)(GroupList);
