import React, { Component } from "react";
import { connect } from "react-redux";
import "./index.less";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { Collapse, Badge } from "antd";
import cn from "classnames";
import GroupList from "./GroupList";
import ChatView from "./ChatView";
import InputArea from "./InputArea";

import { getItem } from "@/utils/common";

const Panel = Collapse.Panel;

class ChatRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: [],
    };
  }
  componentDidMount() {
    // get socket object
    const token = getItem("token");
    if (token) {
      this.props.createWebSocket();
    }
  }
  // componentWillReceiveProps(nextProps) {
  //   if (
  //     nextProps.collapse !== this.props.collapse &&
  //     this.state.activeKey.length > 0
  //   ) {
  //     this.isOpenChat([]);
  //   }
  // }
  isOpenChat = (key) => {
    this.setState({ activeKey: key });
    this.props.collapseChatWindow(key.length > 0);
  };
  render() {
    return (
      <div
        className={cn("chat-room", { none: this.props.room_list.length <= 0 })}
      >
        <div
          className={cn("close-chat", {
            none: this.state.activeKey.length <= 0,
          })}
          onClick={() => this.isOpenChat([])}
        />
        <Collapse onChange={this.isOpenChat} activeKey={this.state.activeKey}>
          <Panel
            header={
              <div className={"collapse-header"}>
                {/*<img className={cn({'gray': this.props.ws_readyState!==WebSocket.OPEN})} alt={''} src={require("@/assets/images/chat_room_headeer_icon.png")} />*/}
                <LegacyIcon
                  className={cn("icon", {
                    gray: this.props.ws_readyState !== WebSocket.OPEN,
                  })}
                  type={"message"}
                  theme="twoTone"
                />
                Message&emsp;
                <Badge
                  overflowCount={999}
                  count={this.props.unread_msg_total}
                />
              </div>
            }
            key={"0"}
          >
            {this.props.active_room ? (
              <div style={{ background: "#f5f5f9", height: "600px" }}>
                <ChatView />
                <InputArea />
              </div>
            ) : (
              <div className="no-chat">
                <img src={require("@/assets/images/nochat-bg.png")} alt="" />
                <p>No chat~</p>
              </div>
            )}
            <GroupList />
          </Panel>
        </Collapse>
      </div>
    );
  }
}

const mapState = (models) => ({
  ws_readyState: models.chatRoom.ws_readyState,
  active_room: models.chatRoom.active_room,
  unread_msg_total: models.chatRoom.unread_msg_total,
  room_list: models.chatRoom.room_list,
});

const mapDispatch = (models) => ({
  createWebSocket: (data) => models.chatRoom.createWebSocket(data),
  collapseChatWindow: (boolean) => models.chatRoom.collapseChatWindow(boolean),
});

export default connect(mapState, mapDispatch)(ChatRoom);
