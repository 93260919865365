export default {
  "login.pageTitle": '登录',
  "login.accountAndPassword": '账号密码登录',
  "login.sms": '手机验证码登录',
  "login.enterEmail": '请输入邮箱',
  "login.enterPassword": '请输入密码',
  "login.passowrd": '密码',
  "login.enterPhone": '请输入手机号',
  "login.sendVerificationCode": '获取验证码',
  "login.enterVerificationCode": '请输入验证码',
  "login.verificationCode": '验证码',
  "login.autoLogin": '自动登录',
  "login.forgotPassword": '忘记密码',
  "login.anyproblemsLoggingIn": '登录遇到问题？',
  "login.help": '帮助',
  "login.helpTips": '如登录遇到问题，请邮件联系support@oros-education.com',
  "login.entryValidArrOrPhone": '请输入有效邮箱地址',
  "login.entryValidPhone": '请输入正确的手机号',
  "login.entryValidPassword": '密码为8到16位，至少包含数字和字母',
  "login.checkoutPassword": '确认密码有误，请重新输入',
  "login.reSend": '重新发送',
  "login.register": '注册',
  "login.LogInWithExistingAccount": '使用已有账户登录',
  "login.retrievePasswordViaEmail": '通过邮箱找回',
  "login.enterValidEmail": '请输入有效邮箱地址',
  "login.enterTheVerificationCodeInEmail": '请输入邮件验证码',
  "login.digitVerificationCode": '6位数邮件验证码',
  "login.RetrievePasswordViaPhoneNumber": '通过手机号找回',
  "login.enterTheVerificationCodeInText": '请输入短信验证码',
  "login.smsVerificationCode": '短信验证码',
  "login.enterThePasswordAgain": '请再次输入密码',
  "login.confirmPassword": '确认密码',
}