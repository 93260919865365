export default {
    "student.title": 'Here you can manage all your students, view/export their class schedule and book classes for them',
    "student.parentPhone": 'Parent’s Phone',
    "student.addStudent": 'Add Student',
    "student.enterName": 'Enter Name',
    "student.timetable": 'Timetable',
    "student.restore": 'Recover User',
    "student.downTimeTableTitle": 'Download Timetable for the Selected Time Range',
    "student.restoreTitle": 'Are you sure you want to recover {name} ？',
    "student.restoreDesc": 'Once recovered, you will be able to view this user’s information under ‘All’',
    "student.editStudent": 'Edit Student Information',
    "student.deleteTitle": 'Are you sure you want to delete {name} ?',
    "student.deleteDesc": 'You will still be able to view this user in the ‘Deleted’ column of this page after deletion.',
    "student.deleteDescOther": 'Once deleted, you’ll be unable to view this user’s information.',
};