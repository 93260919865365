import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Provider } from "react-redux";
import App from "@/pages/App";
import registerServiceWorker from "./registerServiceWorker";
import store from "@/store/index";

import "@/assets/css/common.less";

import "react-app-polyfill/ie9"; // For IE 9-11 support

Sentry.init({
  dsn:
    "https://7eb023a4037c41698d3eb2cd1e23b750@o210908.ingest.sentry.io/5415626",
  // integrations: [new Integrations.BrowserTracing()],
  // tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV, // TODO change this later
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();
