import * as server from '@/utils/api'

const teacher = {
	state: {
        teacherUniversity: [],
        teacherSubject: [],
        teacherJob: [],
        curTeacher: {},
        showTeacherDetail: false,
	},
	reducers: {
    updateCommonState: (state, payload) => {
	    return { ...state, ...payload }
	  }
	},
	effects: {
    handleSomeState (payload) {
      this.updateCommonState(payload)
    },
    selectTeacher (payload) {
        let {showTeacherDetail,curTeacher} = payload
        this.updateCommonState({showTeacherDetail, curTeacher})
    },
    async getTeachers (payload, state) {
      let res = await server.getTeachers(payload)
      return res
    },
    async getTeacherUniversity (payload) {
      let res = await server.getTeacherUniversity(payload)
      if (res && res.status === 200) {
        let university = res.data.university || []
        this.updateCommonState({teacherUniversity: university})
      }
    },
    async getSubject (payload) {
      const lang = window.localStorage.getItem('portal-lang')
      let res = await server.getSubject(payload)
      if (res && res.status === 200) {
        let subjects = (res.data.subjects || []).map(item => {
          let obj = {
            value: item.ID,
            label: lang === 'en' ? item.en_name : item.zh_name,
            children: []
          }
          obj.children = (item.target_groups || []).map(target => {
            let objTartget = {
              value: target.ID,
              label: lang === 'en' ? target.en_name : target.zh_name
            }
            return objTartget
          })
          obj.children.unshift({value: '', label: "ALL"})
          return obj
        })
        this.updateCommonState({teacherSubject: subjects || []})
      }
    },
    async getTeacherJob (payload) {
      let res = await server.getTeacherJob(payload)
      if (res && res.status === 200) {
        let teacherJob = res.data.jobs
        this.updateCommonState({teacherJob})
      }
    },
    async getTeacherInfoById (payload) {
      let res = await server.getTeacherInfoById(payload)
      return res
    },
    async getTeacherReview (payload) {
      let res = await server.getTeacherReview(payload)
      return res
    },
    async getTeacherTimeIsFree (payload) {
      let res = await server.getTeacherTimeIsFree(payload.id, payload.param)
      return res
    },
    async getTeacherTimetable (payload) {
      let res = await server.getTeacherTimetable(payload)
      return res
    },
    async getTeacherUnavailableTimes (payload) {
      let res = await server.getTeacherUnavailableTimes(payload)
      return res
    }
  }
}

export default teacher
