import * as server from '@/utils/api'
import { message } from 'antd'
import store from '../index'

const lang = window.localStorage.getItem('portal-lang')

const agent = {
	state: {
    loading: false,
    list: [],
    mode: 'NORMAL',
    name: '',
    currentPage: 1,
    pageSize: 10,
    total: 0
	},
	reducers: {
    updateCommonState: (state, payload) => {
	    return { ...state, ...payload }
	  }
	},
	effects: {
    handleSomeState (payload) {
      this.updateCommonState(payload)
    },
    async getAgentList (payload, state) {
      this.updateCommonState({loading: true, list: []})
      let res = await server.getAgentList({
        limit: state.agent.pageSize,
        page: state.agent.currentPage,
        status: state.agent.mode,
        name: state.agent.name
      })
      if (res && res.status === 200) {
        let list = res.data.agents || []
        let total = res.data.count || 0
        this.updateCommonState({list: list, total})
        if (state.agent.mode === 'NORMAL' && !state.agent.name) store.dispatch.common.updataTotalPage(total)
      }
      this.updateCommonState({loading: false})
      return res
    },
    async inviteAgent (payload) {
      let res = await server.inviteAgent(payload)
      if (res && res.status === 200) {
        message.success(lang === 'en' ? 'Invite sent successfully; the sign-up email should reach the team member’s email account in under 5 minutes' : '邀请已发送，注册邮件将在5分钟内发送到该员工邮箱')
        this.getAgentList()
      }
      return res
    },
    async editAgent (payload) {
      let res = await server.editAgent(payload.id, payload.option)
      if (res && res.status === 202) {
        if (payload.option.status === 'PENDING_DELETE') {
          message.success(lang === 'en' ? 'Deleted successfully' : '删除成功')
        } else if (payload.option.status === 'NORMAL') {
          message.success(lang === 'en' ? 'Recovered successfully' : '恢复成功')
        } else {
          message.success(lang === 'en' ? 'Edited succesfully' : '修改成功')
        }
        this.getAgentList()
      }
      return res
    },
    async deleteAgent (payload) {
      let res = await server.deleteAgent(payload)
      if (res && res.status === 202) {
        message.success(lang === 'en' ? 'Deleted successfully' : '删除成功')
        this.getAgentList()
      }
    }
  }
}

export default agent
