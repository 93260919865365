export default {
  "common.all": "全部",
  "common.inProgress": "进行中",
  "common.inactive": "不活跃",
  "common.teacherName": "老师姓名",
  "common.studentName": "学生姓名",
  "common.subject": "科目",
  "common.search": "搜索",
  "common.reset": "重置",
  "common.pendingClass": "待上课",
  "common.completed": "已完成",
  "common.cancelled": "已取消",
  "common.pendingTeacher": "待老师",
  "common.pendingTeacherConfirm": "待老师确认",
  "common.pendingStudent": "待学生",
  "common.pendingStudentConfirm": "待学生确认",
  "common.reschedule": "重约",
  "common.confirm": "确认",
  "common.viewDetails": "查看详情",
  "common.addClass": "加课",
  "common.classroomCode": "课堂码",
  "common.university": "学校",
  "common.sex": "性别",
  "common.male": "男",
  "common.female": "女",
  "common.loading": "加载中...",
  "common.chineseName": "中文名",
  "common.englishName": "英文名",
  "common.age": "年龄",
  "common.phone": "手机号",
  "common.operation": "操作",
  "common.total": "共 {total} 条",
  "common.delete": "删除",
  "common.edit": "编辑",
  "common.deleted": "回收站",
  "common.timezone": "时区",
  "common.birth": "生日",
  "common.email": "邮箱",
  "common.cancel": "取消",
  "common.submit": "提交",
  "common.pleaseEnter": "请输入",
  "common.tips.pleaseEnterChineseName": "请输入中文名",
  "common.tips.pleaseEnterEnglishName": "请输入英文名",
  "common.tips.pleaseEnterEmail": "请输入邮箱",
  "common.tips.pleaseEnterAValidPhoneNumber": "请输入正确的手机号",
  "common.tips.pleaseEnterTheCorrectBirthdayFormat":
    "请输入正确的生日格式（YYYY-MM-DD）",
  "common.tips.EmailFormatIsIncorrect": "邮箱格式不正确",
  "common.tips.pleaseSelectStudent": "请选择学生",
  "common.tips.pleaseSelectSubject": "请选择科目",
  "common.status": "状态",
  "common.yes": "是",
  "common.no": "否",
  "common.return": "返回",
  "common.changePassword": "修改密码",
  "common.signOut": "退出登陆",
  "common.currentPassword": "旧密码",
  "common.newPassword": "新密码",
  "common.confirmPassword": "确认密码",
  "common.tips.pleaseEnterCurrentPassword": "请输入旧密码",
  "common.tips.pleaseEnterNewPassword": "请输入新密码",
  "common.tips.pleaseConfirmYourPassword": "请确认密码",
  "common.close": "关闭",
  "common.startTime": "起始时间",
  "common.endTime": "结束时间",
  "common.studentTimezone": "学生所在时区",
  "common.submitSuccessfully": "提交成功",
  "common.submitFailed": "提交失败，请稍后再试",
  "common.classType": "课堂类型",
  "common.oneToOne": "一对一",
  "common.oneToMany": "一对多",
  "common.tips.pleaseSelectClassType": "请选择课堂类型",
  "common.editClassType": "修改课堂类型",
  "common.save": "保存",
  "common.changeClassTypeTipsMany": "确定要将课堂修改为一对多吗？",
  "common.changeClassTypeTipsOne": "确定要将课堂修改为一对一吗？",
  "common.confirmAndEnterClass": "确认并进入教室",
  "common.checkPeopleTipsMany":
    "检测到当前有老师或学生正在教室中，请通知他们刷新页面，或者请您进入教室并将他们踢出。他们再次进入将会进入新的一对多教室",
  "common.checkPeopleTipsOne":
    "检测到当前有老师或学生正在教室中，请通知他们刷新页面，或者请您进入教室并将他们踢出。他们再次进入将会进入新的一对一教室",
};
