export default {
  "login.pageTitle": 'Login',
  "login.accountAndPassword": 'Login using account and password',
  "login.sms": 'Login with SMS verification',
  "login.enterEmail": 'Please enter your email address',
  "login.enterPassword": 'Please enter your password',
  "login.passowrd": 'Passowrd',
  "login.enterPhone": 'Please enter your phone number',
  "login.sendVerificationCode": 'Send verification code',
  "login.enterVerificationCode": 'Please enter verification code',
  "login.verificationCode": 'Verification code',
  "login.autoLogin": 'Auto-login',
  "login.forgotPassword": 'Forgot password?',
  "login.anyproblemsLoggingIn": 'Any problems logging in？',
  "login.help": 'Help',
  "login.helpTips": 'If you encounter any problems logging in, please email support@oros-education.com',
  "login.entryValidArrOrPhone": 'Please enter a valid email address',
  "login.entryValidPhone": 'Please enter a valid phone number',
  "login.entryValidPassword": 'Password must be 8-16 digits long and contain at least 1 number and 1 letter',
  "login.checkoutPassword": 'The "confirm password” field is wrong, please re-enter it',
  "login.reSend": 'Re-send',
  "login.register": 'Register',
  "login.LogInWithExistingAccount": 'Log in with an existing account',
  "login.retrievePasswordViaEmail": 'Retrieve your password via email',
  "login.enterValidEmail": 'Please enter a valid email address',
  "login.enterTheVerificationCodeInEmail": 'Please enter the verification code in email',
  "login.digitVerificationCode": '6 digit verification code in email',
  "login.RetrievePasswordViaPhoneNumber": 'Retrieve your password via phone number',
  "login.enterTheVerificationCodeInText": 'Please enter the verification code in text ',
  "login.smsVerificationCode": 'SMS verification code',
  "login.enterThePasswordAgain": 'Please enter the password again',
  "login.confirmPassword": 'Confirm password',
}