export default {
    "course.title": '这里可以管理所有课程',
    "course.courseManager": '助教',
    "course.reschedule": '重新约课',
    "course.addClass": '加课',
    "course.addClassTitle": '老师将会收到您的订单。在老师确认后，您的学生就可以按时间上课了',
    "course.penalty": '违约费',
    "course.cancelCourseTitle": '你确定要取消以下课程吗？',
    "course.cancelCourse24HoursTitle": '有课程离上课时间不足24小时，你确定要取消该课程吗？',
    "course.cancelCourse24HoursDesc": '上课前24小时内取消将收取{tip}（具体费用详询管理员）',
    "course.rescheduleCourse24HoursTitle": '有课程离上课时间不足24小时，你确定要重约该课程时间吗？',
    "course.rescheduleCourse24HoursDesc": '上课前24小时内修改上课时间将收取{tip}（具体费用详询管理员）',
    "course.cancel": '取消课程',
    "course.courseList": '课程列表',
    "course.auditClassLink": '巡课入口',
    "course.teachingAssistantEntrance": '助教入口',
    "course.classActiveTime": '课堂活跃时间',
    "course.one2many": '1对多',
    "course.and": '和',
    "course.tipsAddClassTime": '请添加上课时间',
    "course.tipsCheck": '请检查重叠的时间段',
    "course.tipsSuccess": '订单创建成功',
    "course.tipsFileExceed": '文件超过50M的大小限制',
    "course.entryFailure": '获取入口失败',
    "course.review": '评价',
    "course.reviewed": '已评价',
    "course.evaluationTitle": '给老师评价',
    "course.evaluationLabelStar": '请您对老师的教学打分',
    "course.evaluationStarTips": '请对老师的教学打分',
    "course.evaluationStarOne": '非常差',
    "course.evaluationStarTwo": '差',
    "course.evaluationStarThree": '一般',
    "course.evaluationStarFour": '满意',
    "course.evaluationStarFive": '非常满意',
    "course.evaluationContent": '请填写对老师的评价',
    "course.evaluationPlaceholder": '写下您对老师的评价，您的意见对老师很重要哦',
    "course.evaluationContentTips": '请填写对老师的评价',
};