import React from 'react'
import './groupAvatar.less'
const groupPhoto = require("@/assets/images/groupPhoto.png");

const GroupAvatar = (props) => {
    return (
        <div className="tx">
            {
                !props.data instanceof Array ?
                    <img src={groupPhoto} alt={""} /> :
                    props.data.map(item=>
                        item?item:null
                    )
            }
        </div>
    )
};
export default GroupAvatar