import * as server from "@/utils/api";
import store from "../index";
import saveAs from "file-saver";

const statistic = {
  state: {
    list: [],
    total: 0,
    loading: false,
    currentPage: 1,
    pageSize: 10,
    exportLoading: false,
    order_by: "asc",
  },
  reducers: {
    updateCommonState: (state, payload) => {
      return { ...state, ...payload };
    },
  },
  effects: {
    handleSomeState(payload) {
      this.updateCommonState(payload);
    },
    async getList(payload, state) {
      this.updateCommonState({ loading: true });
      let res = await server.getOrders({
        ...payload,
        limit: state.statistic.pageSize,
        page: state.statistic.currentPage,
        sort_by: "start_at",
        order_by: state.statistic.order_by,
      });
      if (res && res.status === 200 && res.data) {
        const arr = (res.data.orders || []).map((item) => {
          item.archive = res.data.archive_map[item.id] || [];
          return item;
        });
        this.updateCommonState({
          list: arr,
          total: res.data.count || 0,
        });
        store.dispatch.common.updataTotalPage(res.data.count || 0);
      }
      this.updateCommonState({ loading: false });
    },
    async handleExport(payload, state) {
      this.updateCommonState({ exportLoading: true });
      let res = await server.exportOrders({
        ...payload,
        sort_by: "start_at",
        order_by: state.statistic.order_by,
      });

      if (res && res.status === 200 && res.data) {
        const blob = res.data;
        const filename =
          res.headers?.["content-disposition"]?.replace(
            "attachment; filename=",
            ""
          ) || "课程明细.xlsx";
        saveAs(blob, decodeURIComponent(filename));
      }
      this.updateCommonState({ exportLoading: false });
    },
  },
};

export default statistic;
