import Loadable from 'react-loadable'

const MyLoadingComponent = ({ isLoading, error }) => {
    // Handle the loading state
    if (isLoading) {
        return null
    } else if (error) { // Handle the error state
        return null
    } else {
        return null
    }
}

const Home = Loadable({
    loader: () => import('../pages/Home'),
    loading: MyLoadingComponent
})
const Course = Loadable({
    loader: () => import('../pages/Course'),
    loading: MyLoadingComponent
})
const CourseSingle = Loadable({
    loader: () => import('../pages/Course/Single'),
    loading: MyLoadingComponent
})
const Orders = Loadable({
    loader: () => import('../pages/Orders'),
    loading: MyLoadingComponent
})

const CreateOrder = Loadable({
    loader: () => import('../pages/Orders/CreateOrder'),
    loading: MyLoadingComponent
})

const Teacher = Loadable({
    loader: () => import('../pages/Teacher'),
    loading: MyLoadingComponent
})

const Student = Loadable({
    loader: () => import('../pages/Student'),
    loading: MyLoadingComponent
})

const Staff = Loadable({
    loader: () => import('../pages/Staff'),
    loading: MyLoadingComponent
})

const Statistic = Loadable({
    loader: () => import('../pages/Statistic'),
    loading: MyLoadingComponent
})

const Login = Loadable({
    loader: () => import('../pages/LoginRegForget'),
    loading: MyLoadingComponent
})

const Forget = Loadable({
    loader: () => import('../pages/LoginRegForget/forget'),
    loading: MyLoadingComponent
})

const Reg = Loadable({
    loader: () => import('../pages/LoginRegForget/reg'),
    loading: MyLoadingComponent
})

const ErrorPage = Loadable({
    loader: () => import('../pages/ErrorPage'),
    loading: MyLoadingComponent
})

export default [
	{
		path: '/',
		name: 'course',
		exact: true,
		component: Course
	},
	{
		path: '/course/:id',
		name: 'course',
		exact: true,
		component: CourseSingle
	},
	{
		path: '/order',
		name: 'orders',
		exact: true,
		component: Orders
	},
	{
		path: '/home',
		name: 'Home',
		exact: true,
		component: Home
	},
	{
		path: '/teacher/createOrder',
		name: 'createOrder',
		exact: true,
		component: CreateOrder
	},
	{
		path: '/order/createOrder',
		name: 'createOrder',
		exact: true,
		component: CreateOrder
	},
	{
		path: '/teacher',
		name: 'teacher',
		exact: true,
		component: Teacher
	},
	{
		path: '/student',
		name: 'student',
		exact: true,
		component: Student
	},
	{
		path: '/staff',
		name: 'staff',
		exact: true,
		component: Staff
	},
	{
		path: '/statistic',
		name: 'statistic',
		exact: true,
		component: Statistic
	},
	{
		path: '/login',
		name: 'login',
		exact: true,
		component: Login
	},
  {
		path: '/forget',
		name: 'forget',
		exact: true,
		component: Forget
	},
  {
		path: '/reg',
		name: 'reg',
		exact: true,
		component: Reg
	},
	{
		path: '/404',
		name: 'ErrorPage',
		component: ErrorPage
	}
]
