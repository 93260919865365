export default {
  "common.all": "All",
  "common.inProgress": "In Progress",
  "common.inactive": "Inactive",
  "common.teacherName": "Teacher Name",
  "common.studentName": "Student Name",
  "common.subject": "Subject",
  "common.search": "Search",
  "common.reset": "Reset",
  "common.pendingClass": "Pending Class",
  "common.completed": "Completed",
  "common.cancelled": "Cancelled",
  "common.pendingTeacher": "Pending Teacher",
  "common.pendingTeacherConfirm": "Pending Teacher Confirm",
  "common.pendingStudent": "Pending Student",
  "common.pendingStudentConfirm": "Pending Student Confirm",
  "common.reschedule": "Reschedule",
  "common.confirm": "Confirm",
  "common.viewDetails": "View Details",
  "common.addClass": "Add Class",
  "common.classroomCode": "Classroom Code",
  "common.university": "University",
  "common.sex": "Sex",
  "common.male": "Male",
  "common.female": "Female",
  "common.loading": "loading...",
  "common.chineseName": "Chinese Name",
  "common.englishName": "English Name",
  "common.age": "Age",
  "common.phone": "Phone (mobile)",
  "common.operation": "Operation",
  "common.total": "{total} Items in Total",
  "common.delete": "Delete",
  "common.edit": "Edit",
  "common.deleted": "Deleted",
  "common.timezone": "Timezone",
  "common.birth": "Date of Birth",
  "common.email": "Email",
  "common.cancel": "Cancel",
  "common.submit": "Submit",
  "common.pleaseEnter": "Please Enter",
  "common.tips.pleaseEnterChineseName": "Please Enter Chinese Name",
  "common.tips.pleaseEnterEnglishName": "Please Enter English Name",
  "common.tips.pleaseEnterEmail": "Please Enter Email",
  "common.tips.pleaseEnterAValidPhoneNumber":
    "Please Enter a Valid Phone Number",
  "common.tips.pleaseEnterTheCorrectBirthdayFormat":
    "Please Enter The Correct Birthday Format（YYYY-MM-DD）",
  "common.tips.EmailFormatIsIncorrect": "E-mail Format is Incorrect",
  "common.tips.pleaseSelectStudent": "Please select a student",
  "common.tips.pleaseSelectSubject": "Please select a subject",
  "common.status": "Status",
  "common.yes": "Yes",
  "common.no": "No",
  "common.return": "Return",
  "common.changePassword": "Change Password",
  "common.signOut": "Sign Out",
  "common.currentPassword": "Current Password",
  "common.newPassword": "New Password",
  "common.confirmPassword": "Confirm Password",
  "common.tips.pleaseEnterCurrentPassword": "Please Enter Current Password",
  "common.tips.pleaseEnterNewPassword": "Please Enter New Password",
  "common.tips.pleaseConfirmYourPassword": "Please Confirm Your Password",
  "common.close": "Close",
  "common.startTime": "Start Time",
  "common.endTime": "End Time",
  "common.studentTimezone": "Student Timezone",
  "common.submitSuccessfully": "Successfully submitted",
  "common.submitFailed": "Submission failed. Please try again later",
  "common.classType": "Class type",
  "common.oneToOne": "One to one",
  "common.oneToMany": "One to many",
  "common.tips.pleaseSelectClassType": "Please select class type",
  "common.editClassType": "Edit class type",
  "common.save": "Save",
  "common.changeClassTypeTipsMany":
    "Are you sure to change class type into one to many?",
  "common.changeClassTypeTipsOne":
    "Are you sure to change class type into one to one?",
  "common.confirmAndEnterClass": "Confirm and enter the class",
  "common.checkPeopleTipsMany":
    "It is detected that a teacher or student is currently in the classroom. Please notify them to refresh the page, or please enter the classroom yourself and kick them out of the current classroom in the “student list”. They will enter the new one-to-many classroom when they log in again.",
  "common.checkPeopleTipsOne":
    "It is detected that a teacher or student is currently in the classroom. Please notify them to refresh the page, or please enter the classroom yourself and kick them out of the current classroom in the “student list”. They will enter the new one-to-one classroom when they log in again.",
};
