import * as server from '@/utils/api'

const common = {

    state: {
    },

    reducers: {
        updateCommonState: (state, payload) => {
            return { ...state, ...payload }
        }
    },

    effects: {
        async getCourses (payload) {
            return  await server.getCourses(payload);
        },
        async deleteCourse (payload) {
            return  await server.deleteCourse(payload);
        },
        async getCourseById (payload) {
            return  await server.getCourseById(payload);
        },
        async appendOrdersIntoCourse (payload) {
            return  await server.appendOrdersIntoCourse(payload);
        },
        async reviewByAgent (payload) {
            return  await server.reviewByAgent(payload);
        },
        async checkClassRoom (payload) {
            return  await server.checkClassRoom(payload);
        },
        async changeClassType (payload) {
            return  await server.changeClassType(payload);
        }
    }
};

export default common
