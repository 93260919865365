import moment from "moment";
import jwtDecode from "jwt-decode";

//localStorage获取
export function getItem(key) {
  if (localStorage) {
    let now = moment().valueOf();
    let data = localStorage.getItem(key);
    if (data === null) {
      return null;
    }
    let json = JSON.parse(data);
    if (json && json.expire) {
      if (json.expire >= now) {
        return json.data;
      } else {
        localStorage.removeItem(key);
        return null;
      }
    }
    return json;
  }
  return null;
}
//localStorage存储
export function setItem(key, value, expireSeconds) {
  //30天的期限
  if (localStorage) {
    var data = {
      data: value,
      expire: expireSeconds * 1000,
    };
    localStorage.setItem(key, JSON.stringify(data));
    return true;
  }
  return false;
}
// get URL query
export function getUrlQuery(name) {
  let location = window.location.href;
  let reg = /([^=&?]+)=([^=&?]+)/g;
  let query_obj = {};
  location.replace(reg, (str, $1, $2) => {
    query_obj[$1] = $2;
  });
  return name ? query_obj[name] || "" : query_obj;
}
// 时间格式计算
export function formatTime(times) {
  let times_str = "Unknown time";
  if (times)
    try {
      if (moment().isSame(moment(times), "day")) {
        times_str = moment(times).format("HH:mm");
      } else if (moment().subtract(1, "days").isSame(moment(times), "day")) {
        times_str = "Yesterday " + moment(times).format("HH:mm");
      } else if (moment().subtract(7, "days").isBefore(moment(times))) {
        times_str = moment(times).format("dddd HH:mm");
      } else if (moment().isSame(moment(times), "year")) {
        times_str = moment(times).format("YYYY-MM-DD HH:MM");
      } else {
        times_str = moment(times).format("YYYY-MM-DD HH:MM");
      }
    } catch (e) {
      console.log("[formatTime ERR]:", e);
    }
  return times_str;
}
export function topIndex(arr, index) {
  let temp;
  if (index >= arr.length || index < 1) {
    return arr;
  }
  temp = arr[index];
  arr.splice(index, 1);
  arr.unshift(temp);
  return arr;
}

export function getToken() {
  let token = getItem("token");
  return token ? jwtDecode(token) : {};
}
