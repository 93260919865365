import axios from "axios";
import { message } from "antd";
import { getItem } from "@/utils/common";

const URLS = require("@/store/urlConfig.json"); // url json

const locUpdatedAt = window.localStorage.getItem("loc.updatedAt");
if (
  !locUpdatedAt ||
  new Date().getTime() - locUpdatedAt > 1000 * 24 * 60 * 60
) {
  // update this everyday
  axios.get("https://www.cloudflare.com/cdn-cgi/trace").then((data) => {
    const loc = data.data.split("\n")[8].split("=")[1];
    window.localStorage.setItem("loc", loc);
    window.localStorage.setItem("loc.updatedAt", new Date().getTime());
  });
}

let loc = "-" + window.localStorage.getItem("loc");
if (!URLS[`${process.env.REACT_APP_ENV}${loc}`]) {
  // fallback to global
  loc = "";
  console.log("API fallback to global");
}

const key = `${process.env.REACT_APP_ENV}${loc}`;

export const baseURL = URLS[key].baseURL;
export const wsURL = URLS[key].wsURL;
export const classroomPath = URLS[key].classroomPath;

const lang = window.localStorage.getItem("portal-lang");

axios.defaults.baseURL = baseURL;
axios.defaults.timeout = 15000;
// axios.defaults.withCredentials = true // 表示跨域请求时是否需要使用凭证

// 全局响应请求拦截器
axios.interceptors.response.use(checkStatus, function(err) {
  // 请求超时或者网关报错
  if (err && err.response && /^5\d{2}$/.test(err.response.status)) {
    // 服务端错误
    checkError(lang === "en" ? "Please try again later" : "请稍后再试");
  } else {
    checkError(
      (err && err.response && err.response.data.message) ||
        (lang === "en" ? "Please try again later" : "请稍后再试")
    );
  }
  if (err && err.response && err.response.status === 403) {
    // token过期
    // window.router.history.push('login')
    localStorage.removeItem("agent");
    localStorage.removeItem("token");
    localStorage.removeItem("auth");
    window.router.history.replace("/login");
  }
  return Promise.resolve(err.response);
});

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    notOk(response.data);
  } else {
    var error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
  return response;
}
function notOk(res) {
  if (res && res.code) {
    checkError(res.message);
  }
}
function checkError(msg) {
  message.destroy(); // 先销毁所有的提示，再展示最近的一条提示
  message.error(msg);
}

var request = (options) => {
  // 表单传值参数格式化
  // options.body = Qs.stringify(options.body)

  let token = getItem("token");

  let obj = {};
  if (token) {
    obj.Authorization = `Bearer ${token}`;
  }
  let headers = Object.assign({}, obj, options.headers);
  let config = {
    headers: headers,
    url: options.url,
    method: options.method,
    data: options.body,
    params: options.params,
  };
  if (options.responseType) {
    config.responseType = options.responseType;
  }
  return axios
    .request(config)
    .then(
      (response) => {
        return response;
      },
      (err) => {
        checkError(err.messge);
        throw err;
      }
    )
    .catch((error) => {
      checkError("请求失败");
      throw error;
    });
};
// http请求方式
export var http = {};
var methods = ["get", "post", "put", "delete", "patch"];
methods.forEach((method) => {
  http[method] = (url, params = {}, config = {}) => {
    let headers = {
      "Content-Type": "application/json",
    };
    headers = Object.assign({}, headers, config.headers);
    method = method.toUpperCase();
    if (method === "GET") {
      return request({ url, params, method, headers, ...config });
    }
    return request({ url, body: params, method, headers, ...config });
  };
});
