import * as server from '@/utils/api'

const teacher = {
	state: {
    orderlist: []
	},
	reducers: {
    updateCommonState: (state, payload) => {
	    return { ...state, ...payload }
	  }
	},
	effects: {
    handleSomeState (payload) {
      this.updateCommonState(payload)
    },
    async getOrders (payload, state) {
      let res = await server.getOrders(payload)
      return res
    },
    async createGeneralOrders (payload) {
      let res = await server.createGeneralOrders(payload)
      return res
    },
    async editOrder (payload) {
      let res = await server.editOrder(payload)
      return res
    },
    async editOrders (payload) {
      let res = await server.editOrders(payload)
      return res
    },
    async deleteOrder (payload) {
      let res = await server.deleteOrder(payload)
      return res
    },
    async EnterClassroom (payload) {
      let res = await server.EnterClassroom(payload)
      return res
    },
    async getClasseduRecord (payload) {
      let res = await server.getClasseduRecord(payload)
      return res
    },
    async admin_comment (payload) {
      let res = await server.admin_comment(payload)
      return res
    },
    async getLoginInfo(id) {
      const resp = await server.getLoginInfo(id)
      return resp;
    }
  }
}

export default teacher
