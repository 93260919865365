export default {
    "course.title": 'Here you can manage all current courses',
    "course.courseManager": 'Course Manager',
    "course.reschedule": 'Reschedule',
    "course.addClass": 'Add Class',
    "course.addClassTitle": 'The teacher will receive notification of your booking; once the teacher has confirmed, the student can attend the scheduled class.',
    "course.penalty": 'penalty',
    "course.cancelCourseTitle": 'Are you sure you wish to cancel this class？',
    "course.cancelCourse24HoursTitle": 'This class is less than 24 hours away from now, are you still sure you want to cancel? ',
    "course.cancelCourse24HoursDesc": 'Cancelling with under 24 hour notice will incur a {tip} (please contact your administrator for more information).',
    "course.rescheduleCourse24HoursTitle": 'This class is less than 24 hours away from now, are you still sure you want to reschedule? ',
    "course.rescheduleCourse24HoursDesc": 'Rescheduling with under 24 hour notice will incur a {tip} (please contact your administrator for more information).',
    "course.cancel": 'Cancel',
    "course.courseList": 'Course List',
    "course.auditClassLink": 'Audit Class Link',
    "course.teachingAssistantEntrance": 'Teaching Assistant Entrance',
    "course.classActiveTime": 'Class Active Time',
    "course.one2many": 'One-to-many',
    "course.and": 'And',
    "course.tipsAddClassTime": 'Please add a class time',
    "course.tipsCheck": 'Please double-check the times which have a clash',
    "course.tipsSuccess": 'Order successfully created',
    "course.tipsFileExceed": 'File cannot exceed 50M',
    "course.entryFailure": 'Entry failure',
    "course.review": 'Review',
    "course.reviewed": 'Reviewed',
    "course.evaluationTitle": 'Leave a review for this teacher',
    "course.evaluationLabelStar": "Please rate the teacher's performance",
    "course.evaluationStarTips": "Please rate the teacher's performance out of five",
    "course.evaluationStarOne": 'Terrible',
    "course.evaluationStarTwo": 'Poor',
    "course.evaluationStarThree": 'Average',
    "course.evaluationStarFour": 'Good',
    "course.evaluationStarFive": 'Excellent',
    "course.evaluationContent": 'Please leave a comment',
    "course.evaluationPlaceholder": "Please leave any additional comments here. These comments are so useful to help guide the teacher's performance",
    "course.evaluationContentTips": 'Please leave a written comment',
};